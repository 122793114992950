import React, { useState } from "react";
import { withFirebase, withUser } from "../../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { initialStatePiece, Piece } from "../../../assets/types";
// @ts-ignore
import { SketchField, Tools } from "react-sketch";
import ReactSketch from "../teacher/SketchView";

const EditPiece = (props: {
    user?: any;
    firebase?: any;
    selectedData?: Piece;
    setShow?: any;
    selectData?: any;
    showing?: boolean;
    onMarkedPieceSave: any;
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase } = props;

    const [loading, setLoading] = useState(false);

    const onClose = () => {
        props.setShow(false);
        props.selectData(initialStatePiece);
        setLoading(false);
    };

    //@ts-ignore
    return (
        <Dialog fullScreen scroll="paper" onClose={onClose} open={!!props.showing}>
            <DialogTitle>
                {props.selectedData!.title}
                <IconButton
                    style={{
                        position: "absolute",
                        right: 1,
                        top: 1,
                        color: "grey"
                    }}
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {!props.selectedData!.originalImg && <LinearProgress />}
                <ReactSketch
                    piece={props.selectedData}
                    onMarkedPieceSave={props.onMarkedPieceSave}
                    setPiece={props.selectData}
                    onClose={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

export default withFirebase(withUser(EditPiece));
