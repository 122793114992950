import React, { useState } from "react";
import _ from "lodash";
import { withFirebase, withUser } from "../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const AssignmentPresetDialogue = props => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase } = props;

    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        (async () => {
            const { data } = await firebase.fetchMediaReferenceLibrary();
            setMediaReferences(data.references);
        })();
    }, []);

    React.useEffect(
        () =>
            setFormField({
                ...props.selectedData,
                prepareMaterials: _.compact(_.map(props.selectedData.materials, m => m.uid))
            }),
        [props.selectedData]
    );

    const [mediaReferences, setMediaReferences] = React.useState(null);

    // Controlled Form
    const [form, setFormField] = useState(props.selectedData);

    const onChange = field => value => {
        if (field === "prepareMaterials") {
            setFormField({
                ...form,
                prepareMaterials: value,
                materials: _.compact(
                    _.map(value, selectedUID => mediaReferences.find(({ uid }) => uid === selectedUID))
                )
            });
        } else
            setFormField({
                ...form,
                [field]: value
            });
    };
    // Form submission
    const onSubmit = () => {
        setLoading(true);

        if (props.selectedData.uid) {
            // update
            return firebase
                .updateCourseWorkAssignmentPreset(form)
                .then(success => {
                    enqueueSnackbar(success.data, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "success"
                    });
                    onClose();
                })
                .catch(err => {
                    enqueueSnackbar(err.message, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "error"
                    });
                });
        } else {
            return firebase
                .createNewCourseWorkAssignmentPreset(form)
                .then(success => {
                    enqueueSnackbar(success.data, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "success"
                    });
                    onClose();
                })
                .catch(err => {
                    enqueueSnackbar(err.message, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "error"
                    });
                });
        }
    };

    const onClose = () => {
        const emptySelection = {
            uid: null,
            title: "",
            instructions: "",
            assigneeMode: "ALL_STUDENTS",
            workType: "ASSIGNMENT",
            materials: [],
            prepareMaterials: []
        };
        props.setShow(false);
        props.selectData(emptySelection);
        setLoading(false);
    };

    const { title, assigneeMode, workType, materials, instructions } = form;
    return (
        <Dialog fullScreen scroll="paper" onClose={onClose} open={!!props.showing}>
            <DialogTitle>
                Assignment
                <IconButton
                    style={{
                        position: "absolute",
                        right: 1,
                        top: 1,
                        color: "grey"
                    }}
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {loading && <LinearProgress />}
                {/*Title, Description */}
                <TextField
                    size="medium"
                    label="Title"
                    fullWidth
                    disabled={loading}
                    multiline={false}
                    required={true}
                    value={form.title}
                    onChange={({ target: { value } }) => onChange("title")(value)}
                />
                <TextField
                    labelText="Instructions"
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={({ target: { value } }) => onChange("instructions")(value)}
                    value={instructions}
                    rows={3}
                    multiline
                    disabled={loading}
                />

                {/*Applicable Materials from Media References Library*/}

                <Select
                    label="Reference Media Library"
                    fullWidth
                    multiple={true}
                    value={form.prepareMaterials}
                    renderValue={selected => {
                        return _.compact(
                            _.map(selected, selectedUID => {
                                const selectedMaterial = mediaReferences.find(({ uid }) => uid === selectedUID);
                                if (selectedMaterial)
                                    return `${selectedMaterial.title} by ${selectedMaterial.createdByName}`;
                            })
                        ).join(", ");
                    }}
                    onChange={({ target: { value } }) => onChange("prepareMaterials")(value)}
                >
                    {_.map(mediaReferences, ({ title, createdByName, uid }) => (
                        <MenuItem key={uid} value={uid}>
                            <Checkbox checked={form.prepareMaterials.indexOf(uid) > -1} />
                            <ListItemText primary={`${title} by ${createdByName}`} />
                        </MenuItem>
                    ))}
                </Select>

                <Grid container direction="row" justify="space-evenly" alignItems="flex-start" spacing={3}>
                    {form.materials &&
                        form.materials.map(Reference =>
                            Reference.attachments.map(url => (
                                <Grid item xs={4} sm={3} md={3} key={url}>
                                    <Card>
                                        <CardMedia
                                            style={{
                                                height: "250px"
                                            }}
                                            image={url}
                                        />
                                    </Card>
                                </Grid>
                            ))
                        )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" fullWidth color="primary" onClick={onSubmit} disabled={loading}>
                    {props.selectedData.title ? "Update" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withFirebase(withUser(AssignmentPresetDialogue));
