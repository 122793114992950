import React, { useState } from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { CardActions, TextField } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Card from "@material-ui/core/Card";
import { languages } from "../../assets/variables";
import EnrollDialog from "./EnrollDialog";

const UserProfileReview = props => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase, selectedData } = props;

    const [loading, setLoading] = useState(false);

    const [form, setFormField] = useState(selectedData || {});
    const onChange = field => value =>
        setFormField({
            ...form,
            [field]: value
        });

    React.useEffect(() => {
        if (selectedData) setFormField(selectedData);
    }, [selectedData]);

    const onClose = () => {
        props.setShow(false);
        setLoading(false);
        setFormField({});
    };

    const handleCreatePE = async () => {
        setLoading(true);
        // Create spreadsheet and link it to this student
        firebase
            .createPerformanceReport({
                studentId: uid,
                studentName: `${fName} ${lName}`
            })
            .then(data => {
                enqueueSnackbar("", {
                    variant: "success",
                    persist: false
                });
                setLoading(false);
            })
            .catch(e => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };

    const handleUpdateProfile = () => {
        setLoading(true);
        // Create spreadsheet and link it to this student
        return firebase
            .updateUserProfile(form)
            .then(success => {
                enqueueSnackbar(success.data, {
                    variant: "success",
                    persist: false
                });
                setLoading(false);
                onClose();
            })
            .catch(e => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };

    let {
        fName,
        lName,
        wechatId,
        region,
        tel,
        aboutMe,
        email,
        address,
        city,
        postcode,
        program,
        studentType,
        goals,
        role,
        availability,
        canadianLegalStatus,
        supportedLanguages,
        educationLevel,
        educationDescription,
        profileAvatar,
        pe,
        uid,
        enrollment
    } = form;
    // Make sure the multiple fields are arrays
    if (!Array.isArray(role)) {
        role = [role];
    }
    if (!Array.isArray(studentType)) {
        studentType = [studentType];
    }
    if (!Array.isArray(region)) {
        region = [region];
    }
    if (!Array.isArray(program)) {
        program = [program];
    }

    const courseNames = [
        "Online Course",
        "Art school Portfolio",
        "Portfolio Creation",
        "Children's Classes",
        "Fundamentals Course",
        "Digital Media",
        "Life Drawing Workshop"
    ];

    const [showingEnrollIntoProgram, showEnrollIntoProgram] = React.useState(false);

    return (
        <>
            <Dialog fullWidth maxWidth="xl" onClose={onClose} open={!!props.showing}>
                <DialogTitle>
                    {" "}
                    Review Profile
                    <IconButton
                        style={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            color: "grey"
                        }}
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {loading && <LinearProgress />}
                <DialogContent>
                    <Grid container direction="row" justify="space-between">
                        {/*information*/}
                        <Grid item sm={6} md={8}>
                            <TextField
                                margin="normal"
                                label={"First name"}
                                value={fName}
                                onChange={({ target: { value } }) => onChange("fName")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"Last name"}
                                value={lName}
                                onChange={({ target: { value } }) => onChange("lName")(value)}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                rowsMax={4}
                                multiline
                                label={"About"}
                                value={aboutMe}
                                onChange={({ target: { value } }) => onChange("aboutMe")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"WeChat ID"}
                                value={wechatId}
                                onChange={({ target: { value } }) => onChange("wechatId")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"Email"}
                                value={email}
                                fullWidth
                                onChange={({ target: { value } }) => onChange("email")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"Phone number"}
                                value={tel}
                                onChange={({ target: { value } }) => onChange("tel")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"Address"}
                                rowsMax={4}
                                value={address}
                                onChange={({ target: { value } }) => onChange("address")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"City"}
                                value={city}
                                onChange={({ target: { value } }) => onChange("city")(value)}
                            />
                            <TextField
                                margin="normal"
                                label={"Postcode"}
                                value={postcode}
                                onChange={({ target: { value } }) => onChange("postcode")(value)}
                            />

                            <FormControl margin="normal">
                                <InputLabel>Region</InputLabel>
                                <Select
                                    disabled={loading}
                                    label="Region"
                                    required
                                    multiple
                                    value={region}
                                    onChange={({ target: { value } }) => onChange("region")(value)}
                                >
                                    <MenuItem value="Markham">Markham</MenuItem>
                                    <MenuItem value="Oakville">Oakville</MenuItem>
                                </Select>
                                <FormHelperText>Campus location</FormHelperText>
                            </FormControl>
                            <br />

                            {role.indexOf("student") > -1 && (
                                <>
                                    <FormControl margin="normal" fullWidth>
                                        <Select
                                            fullWidth
                                            value={program}
                                            onChange={({ target: { value } }) => onChange("program")(value)}
                                            options={courseNames}
                                        >
                                            {courseNames.map(name => (
                                                <MenuItem value={name}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Interested in</FormHelperText>
                                    </FormControl>
                                    <br />
                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel>Goal</InputLabel>
                                        <Select
                                            fullWidth
                                            disabled={loading}
                                            help="Student Type"
                                            required
                                            multiple
                                            value={goals || []}
                                            onChange={({ target: { value } }) => onChange("goals")(value)}
                                        >
                                            <MenuItem value="art-foundation">Art Foundations </MenuItem>
                                            <MenuItem value="skill-learning">Skill learning</MenuItem>
                                            <MenuItem value="art-portfolio">Arts Portfolio</MenuItem>
                                            <MenuItem value="interest">For Fun</MenuItem>
                                        </Select>
                                        <FormHelperText>Goal</FormHelperText>
                                    </FormControl>
                                    <FormControl margin="normal">
                                        <InputLabel>Student Type</InputLabel>
                                        <Select
                                            disabled={loading}
                                            required
                                            multiple
                                            value={studentType}
                                            onChange={({ target: { value } }) => onChange("studentType")(value)}
                                        >
                                            <MenuItem value="onCampus">On campus</MenuItem>
                                            <MenuItem value="online">Online</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            Student can be online and\or doing portfolio in class on campus
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            )}

                            {role.indexOf("teacher") > -1 && (
                                <>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        placeholder="I graduated from Seneca College with CPA"
                                        label={"What level of education you have ?"}
                                        value={educationLevel}
                                        onChange={({ target: { value } }) => onChange("educationLevel")(value)}
                                    />

                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        placeholder="I can create design wireframes and prototypes!"
                                        rows={3}
                                        rowsMax={4}
                                        label={"How would you describe your skills?"}
                                        value={educationDescription}
                                        onChange={({ target: { value } }) => onChange("educationDescription")(value)}
                                    />

                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Canadian Legal Status</InputLabel>
                                        <Select
                                            fullWidth
                                            disabled={loading}
                                            required
                                            value={canadianLegalStatus}
                                            onChange={({ target: { value } }) => onChange("canadianLegalStatus")(value)}
                                        >
                                            {["Resident", "Work Permit", "Study Permit", "Ineligible for work"].map(
                                                (l, index) => (
                                                    <MenuItem key={index} value={l}>
                                                        {l}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <FormHelperText>Can you work with us ?</FormHelperText>
                                    </FormControl>

                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        placeholder="I can do part-time 2pm to 5pm 6 days a week!"
                                        rows={3}
                                        rowsMax={4}
                                        label={"What is your availability ?"}
                                        value={availability}
                                        onChange={({ target: { value } }) => onChange("availability")(value)}
                                    />

                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Languages</InputLabel>
                                        <Select
                                            fullWidth
                                            disabled={loading}
                                            label="Region"
                                            required
                                            multiple
                                            value={supportedLanguages || []}
                                            onChange={({ target: { value } }) => onChange("supportedLanguages")(value)}
                                        >
                                            {languages.map((l, index) => (
                                                <MenuItem key={index} value={l.name}>
                                                    {l.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Which languages do you speak ?</FormHelperText>
                                    </FormControl>
                                </>
                            )}

                            <FormControl margin="normal">
                                <InputLabel>System role</InputLabel>
                                <Select
                                    disabled={loading}
                                    label="Role"
                                    required
                                    value={role}
                                    onChange={({ target: { value } }) => onChange("role")(value)}
                                >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="teacher">Teacher</MenuItem>
                                    <MenuItem value="student">Student</MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                        </Grid>
                        {/*photo*/}
                        <Grid
                            item
                            style={{
                                maxWidth: "300px"
                            }}
                            sm={6}
                            md={4}
                        >
                            <Card>
                                <CardMedia>
                                    <img
                                        width={"300px"}
                                        referrerPolicy="no-referrer"
                                        src={profileAvatar}
                                        alt="Avatar"
                                    />
                                </CardMedia>

                                <CardContent>
                                    <h6>{(role === "student" && `${studentType} ${role}`) || role}</h6>
                                    <h4>{fName + " " + lName}</h4>
                                    <p>{aboutMe}</p>

                                    <CardActions>
                                        <Button
                                            margin={"normal"}
                                            variant="outlined"
                                            onClick={() => showEnrollIntoProgram(true)}
                                            color="primary"
                                            fullWidth
                                        >
                                            Enroll into program
                                        </Button>

                                        <EnrollDialog
                                            showing={showingEnrollIntoProgram}
                                            show={showEnrollIntoProgram}
                                            student={props.selectedData}
                                        />

                                        {pe ? (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    margin={"normal"}
                                                    href={pe.spreadsheetUrl}
                                                    target="_blank"
                                                    fullWidth
                                                >
                                                    Open Performance Report
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={loading}
                                                    onClick={handleCreatePE}
                                                    fullWidth
                                                >
                                                    Create Performance Report
                                                </Button>
                                            </>
                                        )}
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button fullWidth variant="outlined" color="primary" onClick={handleUpdateProfile}>
                        Update Profile
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withFirebase(withUser(UserProfileReview));
