import React from "react";
import {
    IonAvatar,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemSliding,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { Program } from "../../../assets/types";
import { Button, createStyles, Fab, Theme } from "@material-ui/core";
import ProgramDetailsDialog from "./ProgramDetailsDialog";
import { withFirebase, withUser } from "../../../core/Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Add } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: "absolute",
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    })
);

function Programs(props: any) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [listing, setListing] = React.useState<Program[]>([]);
    const [usersMeta, setUsers] = React.useState([]);
    const fetchListing = async () => {
        setListing(await props.firebase.getPrograms());
    };
    React.useEffect(() => {
        fetchListing();
    }, []);

    const [selectedData, selectData] = React.useState<Program>();

    const [showingPortfolioDialogue, setShowPortfolioDialogue] = React.useState(false);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Programs</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList>
                    {!listing.length && (
                        <Button fullWidth>
                            <IonItem
                                onClick={() => {
                                    // Enroll
                                }}
                            >
                                <IonLabel>
                                    <h2>No programs</h2>
                                    <h3>Time to create a program or two!</h3>
                                </IonLabel>
                            </IonItem>
                        </Button>
                    )}
                    {listing.map((i: Program, index) => {
                        const { uid, name, description, subjects, previewImg } = i;

                        return (
                            <IonItemSliding key={index}>
                                <IonItem
                                    onClick={() => {
                                        selectData(i);
                                        setShowPortfolioDialogue(true);
                                    }}
                                >
                                    <IonAvatar slot="start">
                                        <img src={previewImg} />
                                    </IonAvatar>
                                    <IonLabel>
                                        {name}
                                        <h3>Students enrolled: X</h3>
                                        <p className={"ion-text-wrap"}>{description}</p>
                                    </IonLabel>
                                </IonItem>
                                {/*<IonItemOptions side="end">
                                    <IonItemOption onClick={() => {}}>Submit for evaluation</IonItemOption>
                                </IonItemOptions>*/}
                            </IonItemSliding>
                        );
                    })}
                </IonList>

                <Fab
                    onClick={() => {
                        enqueueSnackbar(`No way to create a new program is implemented! :(`, {});
                    }}
                    className={classes.fab}
                    color="primary"
                >
                    <Add />
                </Fab>
            </IonContent>

            <ProgramDetailsDialog
                showing={showingPortfolioDialogue}
                setShow={setShowPortfolioDialogue}
                selectData={selectData}
                selectedData={selectedData}
                fetch={fetchListing}
            />
        </IonPage>
    );
}

export default compose(withRouter, withFirebase, withUser)(Programs);
