import React, { useState } from "react";
import { withFirebase, withUser } from "../../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import {
    initialStatePiece,
    initialStatePortfolioSubmission,
    Piece,
    PortfolioSubmission,
    SubmissionStatus
} from "../../../assets/types";
import { CardActions, CardHeader, CardMedia } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { SketchField, Tools } from "react-sketch";
import LinearProgress from "@material-ui/core/LinearProgress";
import Stepper from "../components/Stepper";
import FullScreenPreview from "../../../components/FullScreenPreview";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker } from "@material-ui/pickers";
import EditPiece from "../components/EditPiece";
import CardContent from "@material-ui/core/CardContent";

const PortfolioReview = (props: {
    user?: any;
    firebase?: any;
    selectedData?: PortfolioSubmission;
    setShow?: any;
    selectData?: any;
    showing?: boolean;
    fetch: any;
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase } = props;

    const [loading, setLoading] = useState(false);

    // PortfolioSubmission state
    React.useEffect(() => props.selectedData && setPortfolio(props.selectedData), [props.selectedData]);
    const [portfolio, setPortfolio] = React.useState<PortfolioSubmission>(initialStatePortfolioSubmission);

    // Piece state
    const [showing, show] = React.useState(false);

    // const [form, setForm] = React.useState<any>({});

    // Piece state
    const [showingEditingDialog, showEditingDialog] = React.useState(false);
    const [piece, setPiece] = useState<Piece>(initialStatePiece);

    // Dialog Actions
    const assignTeacher = () => {
        show(false);
        updatePortfolio({
            ...portfolio,
            state: SubmissionStatus.TEACHER_EVALUATING
        });
    };

    const completeRevision = () => {
        updatePortfolio({
            ...portfolio,
            state: SubmissionStatus.COMPLETE
        });
    };

    const updatePortfolio = (portfolio: any, close = true) => {
        setLoading(true);

        return firebase
            .updateStudentsPortfolioSubmission(portfolio)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                setLoading(false);
                close && onClose();
            })
            .catch((err: any) => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onClose = () => {
        props.setShow(false);
        props.selectData(initialStatePortfolioSubmission);
        props.fetch();
    };

    // Fullscreen piece preview
    const [fullScreenPiecePreviewIndex, setFullScreenPiecePreviewIndex] = React.useState(-1);

    // Teacher assignment
    const [teachers, setTeachers] = useState([]);
    React.useEffect(() => {
        (async () => {
            const teachers = await firebase.getTeachers();
            setTeachers(teachers);
        })();
    }, []);

    const onMarkedPieceSave = (piece: Piece) => {
        const attachments = portfolio!.attachments;
        const index = attachments.findIndex(a => piece.originalImg === a.originalImg);

        attachments[index] = piece;
        attachments[index].updatedAt = new Date();

        // console.log("Persisting marked piece", attachments[index]);
        setPortfolio({
            ...portfolio,
            attachments
        });

        updatePortfolio(portfolio, false);
    };

    const {
        subject,
        attachments,
        attachmentsExpected,
        state,
        submissionDueDate,
        evaluationDueDate,
        evaluatedByName
    } = portfolio;
    return (
        <>
            <Dialog fullScreen scroll="paper" onClose={onClose} open={!!props.showing}>
                <DialogTitle>
                    {subject}
                    <IconButton
                        style={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            color: "grey"
                        }}
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {loading && <LinearProgress />}

                    <Grid container spacing={1}>
                        {attachments &&
                            attachments.map((piece: Piece, index: number) => {
                                return (
                                    <Grid xs={12} sm={6} md={4} lg={3} item key={index}>
                                        <Card>
                                            <CardHeader title={piece.title} />
                                            <CardMedia image={piece.originalImg} style={{ height: "140px" }} />
                                            <CardContent>
                                                <TextField
                                                    fullWidth
                                                    helperText="Teacher's feedback to student"
                                                    placeholder={"Devil is in the details..."}
                                                    value={piece.givenFeedback}
                                                    multiline
                                                    rowsMax={6}
                                                    onChange={({ target: { value } }) => {
                                                        const attachments = portfolio.attachments;
                                                        attachments[index].givenFeedback = value;
                                                        attachments[index].updatedAt = new Date();

                                                        setPortfolio({
                                                            ...portfolio,
                                                            attachments
                                                        });
                                                    }}
                                                />
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    onClick={() => {
                                                        setPiece(portfolio!.attachments[index]);
                                                        showEditingDialog(true);
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button onClick={() => setFullScreenPiecePreviewIndex(index)}>
                                                    Fullscreen
                                                </Button>
                                            </CardActions>
                                        </Card>
                                        <FullScreenPreview
                                            fullScreenPiecePreviewIndex={fullScreenPiecePreviewIndex}
                                            setFullScreenPiecePreviewIndex={setFullScreenPiecePreviewIndex}
                                            piece={piece}
                                            index={index}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </DialogContent>

                <Stepper state={state} portfolio={portfolio} />

                <DialogActions>
                    <Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={() => updatePortfolio(portfolio, false)}
                        disabled={loading || state === SubmissionStatus.COMPLETE}
                    >
                        Save changes
                    </Button>
                    {state === SubmissionStatus.BEING_ASSIGNED && (
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => show(true)}
                            disabled={loading}
                        >
                            Assign Teacher
                        </Button>
                    )}
                    {state === SubmissionStatus.ADMIN_REVISING && (
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={completeRevision}
                            disabled={loading}
                        >
                            Complete Revision
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={showing} onClose={() => show(false)}>
                <DialogTitle>Assign teacher to evaluate this portfolio</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        disabled={loading}
                        options={teachers}
                        value={evaluatedByName}
                        onChange={(e, newValue: any) => {
                            //@ts-ignore
                            setPortfolio({
                                ...portfolio,
                                evaluatedBy: newValue.uid,
                                evaluatedByName: `${newValue.fName} ${newValue.lName}`,
                                evaluatedByAvatar: newValue.profileAvatar
                            });
                        }}
                        getOptionLabel={(u: any) => {
                            if (typeof u === "string") return u;
                            if (!u) return "";
                            return `${u.fName} ${u.lName} (${u.role}) ${u.email}`;
                        }}
                        renderInput={params => <TextField {...params} label="Assign teacher..." variant="outlined" />}
                    />

                    <DateTimePicker
                        fullWidth
                        required
                        label="Evaluation Due"
                        value={evaluationDueDate}
                        onChange={evaluationDueDate => {
                            // @ts-ignore
                            setPortfolio({ ...portfolio, evaluationDueDate });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" fullWidth color="primary" onClick={assignTeacher} disabled={loading}>
                        Assign
                    </Button>
                </DialogActions>
            </Dialog>

            <EditPiece
                selectData={setPiece}
                selectedData={piece}
                setShow={showEditingDialog}
                showing={showingEditingDialog}
                onMarkedPieceSave={onMarkedPieceSave}
            />
        </>
    );
};

export default withFirebase(withUser(PortfolioReview));
