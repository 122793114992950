import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useState } from "react";
import { SketchField, Tools } from "react-sketch";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { DialogTitle } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

export default props => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Dialog
            scroll={"body"}
            fullScreen
            open={props.fullScreenPiecePreviewIndex === props.index}
            onClose={() => props.setFullScreenPiecePreviewIndex(-1)}
        >
            <DialogTitle>
                <IconButton
                    style={{
                        position: "absolute",
                        right: 1,
                        top: 1,
                        color: "grey"
                    }}
                    aria-label="close"
                    onClick={() => props.setFullScreenPiecePreviewIndex(-1)}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {props.piece.markedImg ? (
                        <>
                            <Grid item sm={12} md={6} lg={6}>
                                <SketchField
                                    value={props.piece.markedImg}
                                    tool={Tools.Pan}
                                    height={"60vw"}
                                    width={width < 500 ? width : "100%"}
                                />
                                {props.piece.givenFeedback && (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt="" src="" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={props.piece.givenFeedback}
                                            secondary={new Date(props.piece.updatedAt).toDateString()}
                                        />
                                    </ListItem>
                                )}
                            </Grid>
                            <Grid item sm={12} md={6} lg={6}>
                                <img src={props.piece.originalImg} />
                            </Grid>
                        </>
                    ) : (
                        <Grid item sm={12} md={12} lg={12}>
                            <img src={props.piece.originalImg} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
