import React, { useState } from "react";
import _ from "lodash";
import { withFirebase, withUser } from "../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { InputLabel, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { Reference } from "./types";
import FormControl from "@material-ui/core/FormControl";

const ApplyAssignmentDialogue = (props: {
    user: any;
    firebase: any;

    selectedData: Reference;
    show: any;
    showing: boolean;
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase, showing, show } = props;

    const [loading, setLoading] = useState(false);

    const [classrooms, setClassrooms] = useState([]);

    React.useEffect(() => {
        (async () => {
            const { data } = await firebase.fetchClassrooms();
            setClassrooms(data);
        })();
    }, []);

    React.useEffect(() => {
        if (props.selectedData) {
            setReference(props.selectedData);
            setForm({
                title: props.selectedData.title,
                instructions: props.selectedData.instructions,
                classroomId: ""
            });
        }
    }, [props.selectedData]);

    const [form, setForm] = useState({
        title: "",
        classroomId: "",
        instructions: ""
    });
    const [reference, setReference] = useState<Reference>(props.selectedData);

    // Form submission
    const postAssignment = () => {
        setLoading(true);

        return firebase
            .postAssignmentToClassroom(form.classroomId, {
                title: form.title,
                instructions: reference.instructions,
                materials: reference.attachments
            })
            .then((success: any) => {
                enqueueSnackbar(success.data.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });

                window.open(success.data.quickLink, "_blank");
                onClose();
            })
            .catch((err: any) => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });
    };

    const onClose = () => {
        show(false);
        setLoading(false);
    };

    return (
        <Dialog fullWidth maxWidth="md" onClose={onClose} open={showing}>
            <DialogTitle>
                Publish Assignment
                <IconButton
                    style={{
                        position: "absolute",
                        right: 1,
                        top: 1,
                        color: "grey"
                    }}
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {loading && <LinearProgress />}
                {/*Course Selection*/}
                <FormControl margin="normal">
                    <InputLabel id="Classroom">Classroom to publish this to</InputLabel>
                    <Select
                        style={{ width: "320px" }}
                        labelId="Classroom"
                        label="Classroom"
                        onChange={({ target: { value } }) => {
                            // @ts-ignore
                            setForm({
                                ...form,
                                // @ts-ignore
                                classroomId: value
                            });
                        }}
                        value={form.classroomId}
                        disabled={loading}
                    >
                        {_.map(classrooms, ({ name, id: reference_uid }, index) => (
                            <MenuItem key={index} value={reference_uid}>
                                {`${name}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Custom Description */}
                <TextField
                    label="Assignment Title"
                    fullWidth
                    margin="normal"
                    disabled={loading}
                    value={form.title}
                    onChange={({ target: { value } }) =>
                        setForm({
                            ...form,
                            title: value
                        })
                    }
                />

                {/* Custom Description */}
                <TextField
                    label="Instructions"
                    fullWidth
                    margin="normal"
                    disabled={loading}
                    multiline
                    rows={3}
                    rowsMax={32}
                    value={form.instructions}
                    onChange={({ target: { value } }) =>
                        setForm({
                            ...form,
                            instructions: value
                        })
                    }
                />
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={postAssignment} fullWidth variant="contained" disabled={loading}>
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withFirebase(withUser(ApplyAssignmentDialogue));
