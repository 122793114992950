import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase, withUser } from "../../../core/Firebase";
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemSliding,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { PortfolioSubmission } from "../../../assets/types";
import PortfolioDialogue from "./ReviewPortfolio";
import { Button } from "@material-ui/core";
import Label from "../components/QueueListItem";
import { useSnackbar } from "notistack";

function Portfolio(props: any) {
    const [listing, setListing] = React.useState([]);
    const fetchListing = async () => {
        const { data } = await props.firebase.subscribeToStudentsPortfolioSubmissions();
        setListing(data);

        // Automatically update selected portfolio
        if (selectedData) {
            selectData(data.find((s: any) => s.uid === selectedData.uid));
        }
    };
    React.useEffect(() => {
        fetchListing();
    }, []);

    const [selectedData, selectData] = React.useState();

    const [showingPortfolioDialogue, setShowPortfolioDialogue] = React.useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Portfolios</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList>
                    {!listing.length && (
                        <Button fullWidth>
                            <IonItem
                                onClick={() => {
                                    enqueueSnackbar(
                                        `Want to enroll into a portfolio program ? Ask your teacher or administrator to help you out!`
                                    );
                                }}
                            >
                                <IonLabel>
                                    <h2>Not enrolled</h2>
                                    <h3>You are not yet enrolled into a program to have a portfolio review!</h3>
                                    <p>Tap here to enroll!</p>
                                </IonLabel>
                            </IonItem>
                        </Button>
                    )}
                    {listing.map((portfolioSubmission: PortfolioSubmission, index) => {
                        return (
                            <IonItemSliding key={index}>
                                <IonItem
                                    onClick={() => {
                                        selectData(portfolioSubmission);
                                        setShowPortfolioDialogue(true);
                                    }}
                                >
                                    <Label portfolio={portfolioSubmission} role={props.user.role} />
                                </IonItem>
                                {/*{state === SubmissionStatus.UNSUBMITTED && (*/}
                                {/*    <IonItemOptions side="end">*/}
                                {/*        <IonItemOption onClick={() => {}}>Submit for evaluation</IonItemOption>*/}
                                {/*    </IonItemOptions>*/}
                                {/*)}*/}
                            </IonItemSliding>
                        );
                    })}
                </IonList>
            </IonContent>

            <PortfolioDialogue
                showing={showingPortfolioDialogue}
                setShow={setShowPortfolioDialogue}
                selectData={selectData}
                selectedData={selectedData}
                fetch={fetchListing}
            />
        </IonPage>
    );
}

export default compose(withRouter, withFirebase, withUser)(Portfolio);
