import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { initReference, initTag, Reference, Tag } from "./types";
import {
    DeleteForeverOutlined,
    EditOutlined,
    MoreVertOutlined,
    PostAddOutlined,
    RedoOutlined
} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { Card, CardActions, Chip, MenuItem, TextField } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { DropzoneArea } from "material-ui-dropzone";
import CardActionArea from "@material-ui/core/CardActionArea";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import React from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import AttachmentBoard from "./AttachmentBoard";
import PostAssignmentOnClassroom from "./PostAssignmentOnClassroom";
import { useSnackbar } from "notistack";

const ReferencePreview = (props: {
    index: number;
    r: Reference;
    firebase: any;
    user: any;
    role: any;
    fetch: any;
    availableTags: Tag[];
    loading: boolean;
    setLoading: any;
    setReferences: any;
    references: Reference[];
    deleteAt: any;
}) => {
    const {
        index,
        r,
        firebase,
        user,
        fetch,
        availableTags,
        loading,
        setLoading,
        setReferences,
        references,
        deleteAt
    } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

    const [showingAssignmentPostDialog, showAssignmentPostDialog] = React.useState(false);
    const [showingReferenceDetails, showReferenceDetails] = React.useState(false);

    const [selectedReference, selectReference] = React.useState<Reference>(initReference(user));
    const [isCreatingNew, createNew] = React.useState<boolean>(false);
    const [isEditing, edit] = React.useState<boolean>(false);

    React.useEffect(() => {
        selectReference(props.r);
        if (props.r.uid === "uid") {
            createNew(true);
            edit(true);
        }
    }, [props.r]);

    const onCreateReference = () => {
        setLoading(true);
        return firebase
            .createMediaReference(selectedReference, user)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    variant: "success",
                    persist: false
                });
                setLoading(false);

                selectReference(initReference(user));
                createNew(false);
                edit(false);
                fetch();
            })
            .catch((e: any) => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onUpdateReference = () => {
        setLoading(true);
        return firebase
            .updateMediaReference(selectedReference)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    variant: "success",
                    persist: false
                });
                setLoading(false);

                edit(false);
                fetch();
            })
            .catch((e: any) => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onDeleteReference = () => {
        setLoading(true);
        return firebase
            .removeMediaReference(selectedReference)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    variant: "success",
                    persist: false
                });
                setLoading(false);
                props.deleteAt(props.index);
                fetch();
            })
            .catch((e: any) => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };

    return (
        <Card>
            <CardHeader
                avatar={<Avatar src={selectedReference.creator.createdByAvatar} />}
                action={
                    isEditing ? (
                        <>
                            <IconButton
                                onClick={() => {
                                    selectReference(initReference(user));
                                    edit(false);

                                    if (isCreatingNew) {
                                        deleteAt(index);
                                        createNew(false);
                                    }
                                }}
                            >
                                <RedoOutlined />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    key={2}
                                    onClick={() => {
                                        showAssignmentPostDialog(true);
                                        popupState.close();
                                    }}
                                >
                                    <PostAddOutlined /> Post to Classroom
                                </MenuItem>
                                <MenuItem
                                    key={1}
                                    onClick={() => {
                                        edit(true);
                                        popupState.close();
                                    }}
                                >
                                    <EditOutlined /> Edit
                                </MenuItem>
                                {!isCreatingNew && (
                                    <MenuItem
                                        key={0}
                                        onClick={() => {
                                            onDeleteReference();
                                            popupState.close();
                                        }}
                                    >
                                        <DeleteForeverOutlined color="error" /> Delete
                                    </MenuItem>
                                )}
                            </Menu>
                            <IconButton {...bindTrigger(popupState)} aria-label="settings">
                                <MoreVertOutlined />
                            </IconButton>
                        </>
                    )
                }
                title={
                    isEditing ? (
                        <TextField
                            placeholder={"Give a name"}
                            value={selectedReference.title}
                            onChange={({ target: { value } }) =>
                                selectReference({
                                    ...selectedReference,
                                    title: value
                                })
                            }
                        />
                    ) : (
                        r.title
                    )
                }
                subheader={
                    isEditing ? (
                        <TextField
                            placeholder={"Describe it"}
                            value={selectedReference.description}
                            onChange={({ target: { value } }) =>
                                selectReference({
                                    ...selectedReference,
                                    description: value
                                })
                            }
                        />
                    ) : (
                        r.description
                    )
                }
            />

            <CardMedia>
                {isEditing && isCreatingNew ? (
                    <DropzoneArea
                        filesLimit={20}
                        dropzoneText={""}
                        onChange={files => {
                            selectReference({
                                ...selectedReference,
                                //@ts-ignore
                                files
                            });
                        }}
                    />
                ) : (
                    <CardActionArea
                        onClick={() => {
                            edit(false);
                            showReferenceDetails(true);
                        }}
                    >
                        <GridList cellHeight={20} cols={5}>
                            <GridListTile key={0} cols={3} rows={6}>
                                {r.attachments[0] && <img src={r.attachments[0].url} />}
                            </GridListTile>
                            <GridListTile key={2} cols={2} rows={6}>
                                {r.attachments[1] && <img src={r.attachments[1].url} />}
                            </GridListTile>
                        </GridList>
                    </CardActionArea>
                )}
            </CardMedia>

            <CardContent>
                {isEditing ? (
                    <Autocomplete
                        multiple
                        options={availableTags}
                        getOptionLabel={(option: Tag) => option.label}
                        filterSelectedOptions
                        value={selectedReference.tags}
                        freeSolo
                        onChange={(e, tags) => {
                            selectReference({
                                ...selectedReference,
                                tags:
                                    tags &&
                                    tags.map(
                                        (el: any): Tag => {
                                            if (typeof el === "string") {
                                                return initTag(user, el);
                                            } else return el;
                                        }
                                    )
                            });
                        }}
                        renderInput={params => (
                            <TextField {...params} label="Add tags easily find it" placeholder="Oil painting" />
                        )}
                    />
                ) : (
                    r.tags &&
                    r.tags.map((tag: Tag, index) => (
                        <Chip
                            key={index}
                            icon={
                                <Avatar style={{ width: "22px", height: "22px" }} src={tag.creator.createdByAvatar} />
                            }
                            label={tag.label}
                            variant="outlined"
                        />
                    ))
                )}
            </CardContent>

            {isEditing && isCreatingNew && (
                <CardActions>
                    <Button fullWidth variant="outlined" onClick={onCreateReference}>
                        Create
                    </Button>
                </CardActions>
            )}
            {isEditing && !isCreatingNew && (
                <CardActions>
                    <Button fullWidth variant="outlined" onClick={onUpdateReference}>
                        Update
                    </Button>
                </CardActions>
            )}

            <PostAssignmentOnClassroom
                selectedData={selectedReference}
                showing={showingAssignmentPostDialog}
                show={showAssignmentPostDialog}
            />

            <AttachmentBoard
                selectReference={selectReference}
                selectedReference={selectedReference}
                showing={showingReferenceDetails}
                setShow={showReferenceDetails}
                fetch={fetch}
            />
        </Card>
    );
};

export default withUser(withFirebase(ReferencePreview));
