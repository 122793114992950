import React, { useState } from "react";
import { withFirebase, withUser } from "../../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
    initialStatePiece,
    initialStatePortfolioSubmission,
    Piece,
    PortfolioSubmission,
    SubmissionStatus
} from "../../../assets/types";
import { CardActions, CardHeader, CardMedia, TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import _ from "lodash";
import Stepper from "../components/Stepper";
import FullScreenPreview from "../../../components/FullScreenPreview";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

const AssignmentPresetDialogue = (props: {
    user?: any;
    firebase?: any;
    selectedData?: PortfolioSubmission;
    setShow?: any;
    selectData?: any;
    showing?: boolean;
    fetch: any;
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase } = props;

    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        props.selectedData && setPortfolio(props.selectedData);
    }, [props.selectedData]);

    // Form that holds the
    const [portfolio, setPortfolio] = useState<PortfolioSubmission>(initialStatePortfolioSubmission);
    const [selectedPiece, selectPiece] = useState<Piece>(initialStatePiece);

    const [file, selectFile] = useState<File[]>();

    const onChange = (field: string) => (value: any) => {
        selectPiece({
            ...selectedPiece,
            [field]: value
        });
    };

    const onUpload = async () => {
        setLoading(true);
        const createNewPiece = (title: string, originalImg: string) => {
            if (!originalImg)
                return enqueueSnackbar("Attach an image", {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "info"
                });

            return _.compact(
                _.concat(portfolio.attachments, [
                    {
                        title,
                        originalImg,
                        markedImg: {}
                    }
                ])
            );
        };

        const [attachmentUrl] = await firebase.uploadAttachments(file);
        const attachments = createNewPiece(selectedPiece.title, attachmentUrl);

        await firebase.updateStudentsPortfolioSubmission({
            ...portfolio,
            attachments
        });
        selectPiece(initialStatePiece);
        selectFile(undefined);
        setLoading(false);
        await props.fetch();
    };

    const onChangePiece = (field: string) => (index: number) => (value: string) => {
        const attachments = portfolio.attachments;
        // @ts-ignore
        attachments[index][field] = value;

        setPortfolio({
            ...portfolio,
            attachments
        });
    };

    const onDeletePiece = (index: number) => {
        let attachments = portfolio.attachments;
        attachments.splice(index, 1);
        setPortfolio({
            ...portfolio,
            attachments
        });
    };

    const onUpdate = (submit = false) => {
        setLoading(true);

        if (submit) {
            portfolio.submittedAt = new Date().toJSON();
            portfolio.state = SubmissionStatus.BEING_ASSIGNED;
        }

        return firebase
            .updateStudentsPortfolioSubmission(portfolio)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                setLoading(false);
                props.fetch();
                submit && onClose();
            })
            .catch((err: any) => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
            });
    };

    const onClose = () => {
        props.setShow(false);
        props.selectData(initialStatePortfolioSubmission);
    };

    // Fullscreen piece preview
    const [fullScreenPiecePreviewIndex, setFullScreenPiecePreviewIndex] = React.useState(-1);

    const { program, subject, attachments, attachmentsExpected, state, submissionDueDate } = portfolio;
    return (
        <Dialog fullScreen scroll="paper" onClose={onClose} open={!!props.showing}>
            <DialogTitle>
                {program}: {subject}
                <IconButton
                    style={{
                        position: "absolute",
                        right: 1,
                        top: 1,
                        color: "grey"
                    }}
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {attachments &&
                        attachments.map((piece: Piece, index) => {
                            return (
                                <Grid xs={12} sm={6} md={4} lg={3} item key={index}>
                                    <Card>
                                        <CardHeader
                                            title={
                                                state === SubmissionStatus.UNSUBMITTED ? (
                                                    <TextField
                                                        fullWidth
                                                        placeholder={"Title"}
                                                        value={piece.title}
                                                        onChange={({ target: { value } }) =>
                                                            onChangePiece("title")(index)(value)
                                                        }
                                                    />
                                                ) : (
                                                    piece.title
                                                )
                                            }
                                        />
                                        <CardMedia onClick={() => setFullScreenPiecePreviewIndex(index)}>
                                            <img src={piece.originalImg} />
                                        </CardMedia>
                                        <CardContent>
                                            {piece.givenFeedback && (
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar alt="" src="" />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={piece.givenFeedback}
                                                        secondary={new Date(piece.updatedAt).toDateString()}
                                                    />
                                                </ListItem>
                                            )}
                                        </CardContent>
                                        <FullScreenPreview
                                            fullScreenPiecePreviewIndex={fullScreenPiecePreviewIndex}
                                            setFullScreenPiecePreviewIndex={setFullScreenPiecePreviewIndex}
                                            piece={piece}
                                            index={index}
                                        />

                                        {state === SubmissionStatus.UNSUBMITTED && (
                                            <CardActions>
                                                <Button
                                                    size="small"
                                                    color="secondary"
                                                    onClick={() => onDeletePiece(index)}
                                                >
                                                    DELETE
                                                </Button>
                                            </CardActions>
                                        )}
                                    </Card>
                                </Grid>
                            );
                        })}
                    {state === SubmissionStatus.UNSUBMITTED && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card>
                                <CardHeader
                                    title={
                                        <TextField
                                            fullWidth
                                            placeholder={"Title"}
                                            value={selectedPiece.title}
                                            onChange={({ target: { value } }) => onChange("title")(value)}
                                        />
                                    }
                                />
                                <CardContent>
                                    {loading ? (
                                        <LinearProgress />
                                    ) : (
                                        <DropzoneArea
                                            filesLimit={1}
                                            acceptedFiles={[
                                                "application/pdf",
                                                "image/jpeg",
                                                "image/png",
                                                "image/bmp",
                                                "image/jpg"
                                            ]}
                                            onChange={selectFile}
                                        />
                                    )}
                                </CardContent>

                                {selectedPiece.title && file && (
                                    <CardActions>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            color="primary"
                                            onClick={onUpload}
                                            disabled={loading}
                                        >
                                            Upload
                                        </Button>
                                    </CardActions>
                                )}
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <Stepper state={state} portfolio={portfolio} />

            {state === SubmissionStatus.UNSUBMITTED && (
                <DialogActions>
                    <Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={() => onUpdate(false)}
                        disabled={loading}
                    >
                        Save changes
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => onUpdate(true)} disabled={loading}>
                        Submit for evaluation
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default withFirebase(withUser(AssignmentPresetDialogue));
