import _ from "lodash"
import React from "react"
import Button from "@material-ui/core/Button"

export const renderUsersName = userTableData => property => row => {
    // console.log(userTableData, row[property])
    if (Array.isArray(row[property])) {
        const users = _.compact(
            _.map(row[property], userId => {
                return _.find(userTableData, ({uid}) => uid === userId)
            })
        )

        return _.map(users, ({fName, lName}, index) => {
            return <span key={index}>{`${fName} ${lName}, `}</span>
        })
    } else if (typeof row[property] === "string") {
        const usr = _.find(userTableData, ({uid}) => uid === row[property])

        if (usr) {
            const {fName, lName} = usr
            return <span>{`${fName} ${lName}`}</span>
        } else {
            return <span>No users assigned</span>
        }
    } else if (!row[property]) {
        return <span>No users assigned</span>
    }
}

export const renderNumberOfUsers = userTableData => property => row => {
    // Course reference
    let output = []
    if (row[property] && _.compact(row[property]).length) {
        _.forEach(row[property], sid => {
            const student = _.filter(userTableData, u => {
                if (u.uid === sid) return u
            })
            output.push(student)
        })

        // if (usr.length) {
        //     _.forEach(usr, u => {
        //
        //     })
        //     const {fName, lName} = usr
        //     output += <span>{`${fName} ${lName}`}</span>
        // }
    }
    return output.length
}
export const renderRoleName = ({role}) => {
    switch (role) {
        case "student":
            return "Student"
        case "teacher":
            return "Teacher"
        case "admin":
            return "Studio Admin"
    }
}

export const renderFinanceAmount = row => {
    switch (row["type"]) {
        case "Tuition Fee":
            return `+ ${row.currency} ${row["amount"]}`
        default:
            return `- ${row.currency} ${row["amount"]}`
    }
}
export const renderCoursePrice = row => `$ ${row["price"]}`
export const renderDuration = row => {
    if (row["duration"] <= 1) return `${row["duration"]} hr`
    return `${row["duration"]} hrs`
}

export const renderStudentType = ({studentType}) => {
    if (Array.isArray(studentType)) {
        studentType = _.compact(studentType)

        if (studentType.length === 1) {
            return <span>{`${studentType}`}</span>
        } else if (studentType.length > 1) {
            return _.map(studentType, (type, index) => {
                if (index === studentType.length - 1) return <span key={index}>{`${type}`}</span>
                return <span key={index}>{`${type}, `}</span>
            })
        }
    }
}

export const renderRegion = ({region}) => {
    //hotfix
    return region
    if (Array.isArray(region)) {
        region = _.compact(region)

        if (region.length === 1) {
            return <span>{`${region}`}</span>
        } else if (region.length > 1) {
            console.log(
                _.map(region, (type, index) => {
                    if (index === region.length - 1) return <span key={index}>{`${type}`}</span>
                    return <span key={index}>{`${type}, `}</span>
                })
            )
        }
    }
}

export const renderEmail = row => field => (
    <Button variant="contained" target={"_blank"} href={`email:to:${row[field]}`}>
        Email
    </Button>
)
export const renderUrl = row => field => (
    <Button variant="outlined" target={"_blank"} href={`${row[field]}`}>
        Open
    </Button>
)
