import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonToolbar } from "@ionic/react";
import { Redirect, Route, withRouter } from "react-router-dom";
import Stream from "./Stream";
import Classwork from "./ClassWork";
import People from "./People";
import Marks from "./Marks";

export default withRouter((props: any) => {
    const { history, match } = props;
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />

                        <Tabs value={0} indicatorColor="primary" textColor="primary" variant="fullWidth">
                            <Tab label="Stream" onClick={() => history.push(`${match.url}/stream`)} />
                            <Tab label="Class Work" onClick={() => history.push(`${match.url}/classwork`)} />
                            <Tab label="People" onClick={() => history.push(`${match.url}/people`)} />
                            <Tab label="Marks" onClick={() => history.push(`${match.url}/marks`)} />
                        </Tabs>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <Route exact path={`${match.url}/stream`} component={Stream} />
                <Route exact path={`${match.url}/classwork`} component={Classwork} />
                <Route exact path={`${match.url}/people`} component={People} />
                <Route exact path={`${match.url}/marks`} component={Marks} />
                <Redirect to={`${match.url}/stream`} />
            </IonContent>
        </IonPage>
    );
});
