import React, { useState } from "react";
import _ from "lodash";
import { withFirebase, withUser } from "../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MUIRichTextEditor from "mui-rte";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";

import { stateToHTML } from "draft-js-export-html";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ComposeEmailDialog = props => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase, selectedData, users } = props;

    const [loading, setLoading] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [searchableUsers, setSearchableUsers] = useState([]);

    const [form, setFormField] = useState(
        props.selectedData || {
            to: [],
            toAll: false,
            from: "no-reply@aureolestudios.ca"
        }
    );
    const onChange = field => value => {
        setFormField({
            ...form,
            [field]: value
        });
    };

    React.useEffect(() => {
        (async () => {
            // const { data } = await firebase.doGetRecipientList();
            // setRecipients(data);
            // console.log(recipients);
        })();
    }, []);
    React.useEffect(() => {
        (async () => {
            if (Array.isArray(selectedData) && selectedData.length) {
                setFormField({
                    to: selectedData.map(({ email }) => email)
                });
            } else if (selectedData.email) {
                setFormField({
                    to: [selectedData.email]
                });
            } else {
                setFormField({
                    to: []
                });
            }
        })();
    }, [selectedData]);

    // Form submission
    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);

        firebase
            .doSendEmail({
                ...form,
                html: stateToHTML(form.emailContent.getCurrentContent())
            })
            .then(({ data }) => {
                enqueueSnackbar(data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                setLoading(false);
                onClose();
            })
            .catch(err => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });
    };

    const onClose = () => {
        props.setShow(false);
        setLoading(false);
        // setFormField({
        //     to: []
        // })
    };

    const { subject, from, to, toAll } = form;
    return (
        <>
            <Dialog fullWidth maxWidth="xl" onClose={onClose} open={!!props.showing}>
                <form onSubmit={onSubmit}>
                    <DialogTitle>
                        Send Announcement Email
                        <IconButton
                            style={{
                                position: "absolute",
                                right: 1,
                                top: 1,
                                color: "grey"
                            }}
                            aria-label="close"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {loading && <LinearProgress />}
                    <DialogContent style={{ height: "70vh" }}>
                        <Grid container spacing={4}>
                            <Grid item md={6}>
                                <TextField
                                    variant="outlined"
                                    label="Subject Line"
                                    fullWidth
                                    disabled={loading}
                                    value={subject}
                                    onChange={({ target: { value } }) => onChange("subject")(value)}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Autocomplete
                                    disabled={loading || form.toAll}
                                    id="to"
                                    options={users} // TODO was `users`
                                    multiple
                                    variant=""
                                    value={
                                        to &&
                                        to.map(email => {
                                            const [i] = users.filter(u => u.email === email);
                                            return i;
                                        })
                                    }
                                    onChange={(e, newValue) => {
                                        onChange("to")(_.map(newValue, u => u.email));
                                    }}
                                    getOptionLabel={u => {
                                        return `${u.fName} ${u.lName} (${u.role}) ${u.email}`;
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            disabled={loading || form.toAll}
                                            {...params}
                                            label="Referenced User..."
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Checkbox
                                    checked={form.toAll}
                                    onClick={() => {
                                        onChange("toAll")(!form.toAll);
                                    }}
                                />
                                Send to Everyone
                            </Grid>
                        </Grid>
                        <MUIRichTextEditor
                            onChange={value => onChange("emailContent")(value)}
                            label="Start typing..."
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth color="primary" variant="contained" type="submit" disabled={loading}>
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default withFirebase(withUser(ComposeEmailDialog));
