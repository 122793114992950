import React from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import MaterialTable from "material-table";
import AssignmentDialogue from "./AssignmentPresetDialogue";
import ApplyAssignmentModal from "./ApplyAssignmentDialogue";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";

function AssignmentListing(props) {
    const { firebase, user } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // CourseWork: AssignmentMaterials State
    const [showingForm, setShowAssignmentDialogue] = React.useState(false);

    // CourseWork: Application State
    const [showingApplyForm, setShowApplyForm] = React.useState(false);
    const [selectedAssignment, selectAssignment] = React.useState({
        uid: null,
        title: "",
        instructions: "",
        workType: "ASSIGNMENT",
        assigneeMode: "ALL_STUDENTS",

        materials: [],
        prepareMaterials: []
    });

    // CourseWork Presets
    const [presets, setPresets] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            firebase.subscribeToCourseWorkAssignmentPresets().on("value", snapshot => {
                if (!snapshot.exists) return console.error("No Data received");
                let flattened = [];
                snapshot.forEach(snap => {
                    flattened.push({
                        uid: snap.key,
                        ...snap.val()
                    });
                });
                setPresets(flattened);
            });
        })();
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Assignment templates (presets)</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <MaterialTable
                    data={presets}
                    title="Assignments"
                    columns={[
                        {
                            title: "Title",
                            field: "title",
                            headerStyle: {
                                //backgroundColor: '#039be5',

                                maxWidth: "40px" // TODO: reduce column width
                            }
                        },
                        {
                            title: "Instructions",
                            field: "instructions",
                            cellStyle: {
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: 100
                            }
                        }
                        // {title: "URL",  render: url => <Button href={url.alternateLink} target={'_blank'}>Open</Button>},
                    ]}
                    // options={{
                    //     exportButton: true,
                    //     exportFileName: 'Courses.csv',
                    //     exportDelimiter: ',',
                    //     searchFieldAlignment: 'left',
                    //     search: true
                    // }}
                    actions={[
                        {
                            icon: "add",
                            tooltip: "Add Assignment",
                            isFreeAction: true,
                            onClick: () => setShowAssignmentDialogue(true)
                        },
                        {
                            icon: "edit",
                            tooltip: "Edit Assignment",
                            isFreeAction: false,
                            onClick: (_, row) => {
                                selectAssignment(row);
                                setShowAssignmentDialogue(true);
                            }
                        },
                        {
                            icon: "publish",
                            tooltip: "Publish Draft on Classroom",
                            isFreeAction: false,
                            onClick: (_, row) => {
                                selectAssignment(row);
                                setShowApplyForm(true);
                            }
                        },
                        {
                            icon: FileCopyIcon,
                            tooltip: "Duplicate",
                            isFreeAction: false,
                            onClick: (_, row) => {
                                delete row.uid;

                                selectAssignment({
                                    ...row,
                                    id: null,
                                    title: `${row.title} (Duplicate)`
                                });
                                setShowAssignmentDialogue(true);
                            }
                        }
                    ]}
                    editable={{
                        onRowDelete: rowData =>
                            firebase
                                .removeCourseWorkAssignmentPreset(rowData)
                                .then(success => {
                                    enqueueSnackbar(success.data, {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        variant: "info"
                                    });
                                })
                                .catch(err => {
                                    enqueueSnackbar(err.message || "Oh oh, something went wrong!", {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        variant: "error"
                                    });
                                })
                    }}
                />

                <ApplyAssignmentModal
                    showing={showingApplyForm}
                    setShow={setShowApplyForm}
                    selectedData={selectedAssignment}
                />

                <AssignmentDialogue
                    showing={showingForm}
                    setShow={setShowAssignmentDialogue}
                    selectData={selectAssignment}
                    selectedData={selectedAssignment}
                />
            </IonContent>
        </IonPage>
    );
}

export default compose(withUser, withFirebase)(AssignmentListing);
