import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { withFirebase, withUser } from "../../core/Firebase";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Attachment, initAttachment, Reference } from "./types";
import { Add } from "@material-ui/icons";
import { createStyles, Fab, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AttachmentPreview from "./AttachmentPreview";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: "absolute",
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    })
);

const AttachmentBoard = (props: {
    selectedReference: Reference;
    selectReference: any;
    firebase: any;
    user: any;
    fetch: any;
    showing: boolean;
    setShow: any;
}) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { firebase, fetch, user, showing, setShow, selectedReference, selectReference } = props;

    const [loading, setLoading] = useState(false);

    const onClose = () => {
        setLoading(false);
        // fetch();
        props.setShow(false);
    };
    const [attachments, setAttachments] = React.useState<Attachment[]>([]);
    React.useEffect(() => {
        (() => setAttachments(props.selectedReference.attachments))();
    }, [props.selectedReference]);
    let { title, description } = props.selectedReference;

    return (
        <Dialog fullWidth maxWidth="xl" onClose={onClose} open={showing}>
            <DialogTitle>
                {title}
                <IconButton
                    style={{
                        position: "absolute",
                        right: 1,
                        top: 1,
                        color: "grey"
                    }}
                    aria-label="close"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
                {loading && <LinearProgress />}
            </DialogTitle>

            <DialogContent style={{ minHeight: "250px" }}>
                <Grid container spacing={1} justify="center" alignContent="space-around">
                    {attachments.length ? (
                        attachments.map((a: Attachment, index: number) => (
                            <Grid key={index} item sm={6} md={4} lg={3} xl={2}>
                                <AttachmentPreview
                                    index={index}
                                    a={a}
                                    selectedReference={selectedReference}
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                    loading={loading}
                                    setLoading={setLoading}
                                    fetch={fetch}
                                />
                            </Grid>
                        ))
                    ) : (
                        <p>Nothing here yet. Upload your first attachment!</p>
                    )}
                </Grid>

                <Fab
                    onClick={() => {
                        const newAttachment = initAttachment(user);

                        // @ts-ignore
                        setAttachments([newAttachment, ...attachments]); // put it on board right away
                    }}
                    className={classes.fab}
                    color="primary"
                    aria-label="add"
                >
                    <Add />
                </Fab>
            </DialogContent>
        </Dialog>
    );
};

export default withUser(withFirebase(AttachmentBoard));
