import React from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { IonContent, IonPage } from "@ionic/react";

const GivePermissions = props => {
    const {
        firebase,
        user: { uid }
    } = props;
    return (
        <IonPage>
            <IonContent>
                <Grid container justify="center">
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar alt="" src="" />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Give permissions to the application."
                                secondary="Click below to continue"
                            />
                        </ListItem>

                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={async () => {
                                await firebase.giveAdministrativePermissions(uid);
                            }}
                        >
                            Give permissions!
                        </Button>
                    </Grid>
                </Grid>
            </IonContent>
        </IonPage>
    );
};

export default withUser(withFirebase(GivePermissions));
