import React from "react";
import CardContent from "@material-ui/core/CardContent";
import MaterialTable from "material-table";
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";

function StudentTuitionFees(props) {
    const { firebase, user } = props;
    const { ...rest } = props;

    const [tableData, setTableData] = React.useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    React.useEffect(() => {
        (async () => {
            const { data: transactions } = await firebase.fetchTransactionsFor();
            setTableData(transactions);
        })();
    }, []);

    return (
        <>
            <Card>
                <CardHeader color="primary">
                    <h3>Finances</h3>
                    <p>Tuition fees</p>
                </CardHeader>
                <CardContent>
                    {!tableData ? (
                        <CircularProgress />
                    ) : (
                        <MaterialTable
                            columns={[
                                { title: "Type", field: "type" },
                                { title: "Status", field: "status" },
                                { title: "Remark", field: "remark" },
                                { title: "Payment Method", field: "paymentMethod" },
                                { title: "Amount", field: "amount" },
                                {
                                    title: "Published",
                                    field: "createdAt",
                                    type: "date",
                                    render: row => new Date(row.createdAt).toLocaleString("en-US")
                                }
                            ]}
                            data={tableData}
                            title=""
                            options={{
                                exportButton: true,
                                exportFileName: "AureoleStudios - Tuition Fees.csv",
                                exportDelimiter: ",",
                                searchFieldAlignment: "left",
                                search: true
                            }}
                        />
                    )}
                </CardContent>
            </Card>

            {/*{showingPaymentForm &&*/}
            {/*<PaymentForm*/}
            {/*        {...rest}*/}
            {/*        showingPaymentForm={showingPaymentForm}*/}
            {/*        setShowPaymentForm={setShowPaymentForm}*/}
            {/*/>*/}
            {/*}*/}
        </>
    );
}

export default compose(withFirebase, withUser)(StudentTuitionFees);
