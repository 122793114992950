import React from "react";
import MaterialTable from "material-table";
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import TransactionForm from "./TransactionDialog";
import { renderFinanceAmount, renderUsersName } from "../../core/helperFunctions";
import _ from "lodash";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { LinearProgress } from "@material-ui/core";

function AdminTransactionTable(props) {
    const {
        firebase,
        user: { region: currentFirebaseUserRegion }
    } = props;

    // UI
    const [loading, setLoading] = React.useState(true);
    const [loadingUsers, setLoadingUsers] = React.useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [showingTransactionForm, setShowTransactionForm] = React.useState(false);
    const [selectedTransactionData, selectTransactionData] = React.useState({
        remark: "",
        type: "",
        userReference: "",
        paymentMethod: "",
        amount: ""
    });

    // Constant storage
    const [transactionStore, setTransactionStore] = React.useState([]);

    const [tableData, setTableData] = React.useState(false);
    const [plotData, setPlotData] = React.useState([]);
    const [users, setUserTableData] = React.useState([]);

    const computeDataViz = (selectedRegion = whichRegion(), transactions = transactionStore) => {
        // console.log("transactionStore", transactions, "region: ", selectedRegion);
        //Granted transactions are sorted by date
        const Sales = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines+markers",
            marker: { color: "green" },
            name: "Sales"
        };
        const Expenses = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines+markers",
            marker: { color: "orange" },
            name: "Expenses"
        };

        const notAnnulledPayrollTransactions = transactions.filter(
            ({ status, region }) => status !== "Annulled" && region === selectedRegion
        );

        _.forEach(notAnnulledPayrollTransactions, transaction => {
            let page; // reusable dictionary indexer
            const createdAt = moment(transaction.createdAt)
                .endOf("month")
                .toJSON();

            switch (transaction.type) {
                case "Payroll": {
                    page = Expenses.x.indexOf(createdAt);
                    if (page > -1) {
                        Expenses.y[page] += transaction.total;
                    } else {
                        Expenses.x.push(createdAt);
                        Expenses.y.push(transaction.total);
                    }
                    break;
                }
                case "Tuition Fee": {
                    page = Sales.x.indexOf(createdAt);
                    if (page > -1) {
                        Sales.y[page] += transaction.total;
                    } else {
                        Sales.x.push(createdAt);
                        Sales.y.push(transaction.total);
                    }
                    break;
                }
                case "Business Expense": {
                    page = Expenses.x.indexOf(createdAt);
                    if (page > -1) {
                        Expenses.y[page] += transaction.total;
                    } else {
                        Expenses.x.push(createdAt);
                        Expenses.y.push(transaction.total);
                    }
                    break;
                }
            }
        });

        setPlotData([Sales, Expenses]);
    };
    const computeTable = (selectedRegion = whichRegion(), transactions = transactionStore) => {
        setTableData(transactions.filter(({ region }) => region === selectedRegion));
    };

    // One-time load
    React.useEffect(() => {
        (async () => {
            const { data } = await firebase.fetchTransactionsFor(selectedRegionTab);
            setTransactionStore(data);

            computeDataViz(whichRegion(), data);
            computeTable(whichRegion(), data);

            firebase.subscribeToUsers().on("value", snapshot => {
                if (!snapshot.exists) return console.error("No Data received");

                let flattened = [];
                snapshot.forEach(snap => {
                    flattened.push({
                        uid: snap.key,
                        ...snap.val()
                    });
                });
                setUserTableData(flattened);
                setLoading(false);
            });
        })();
    }, []);

    // Campus switcher. If admin is located in Oakville, the user will only see (1) Oakville transactions.
    // If admin is in (0) Markham, admin will see all transactions
    const [selectedRegionTab, selectRegion] = React.useState(
        currentFirebaseUserRegion.indexOf("Markham") === -1 ? 1 : 0
    );
    // get (0) 'Markham'\ (1)'Oakville' out of a tab
    const whichRegion = (tab = selectedRegionTab) => {
        let region;
        if (tab === 0) region = "Markham";
        else if (tab === 1) region = "Oakville";
        return region;
    };

    return (
        <IonPage>
            {loading && <LinearProgress />}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Transactions</IonTitle>
                    <IonButtons slot="end">
                        <Tabs
                            value={selectedRegionTab}
                            onChange={(e, tab) => {
                                selectRegion(tab);
                                // onSwitch update the table and viz
                                computeDataViz(whichRegion(tab), transactionStore);
                                computeTable(whichRegion(tab), transactionStore);
                            }}
                        >
                            <Tab label="Markham" disabled={currentFirebaseUserRegion.indexOf("Markham") === -1} />
                            <Tab label="Oakville" />
                        </Tabs>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                {tableData && (
                    <MaterialTable
                        localization={{
                            body: {
                                editRow: {
                                    deleteText: "Remove ?"
                                }
                            }
                        }}
                        columns={[
                            { title: "Status", field: "status" },
                            { title: "Remark", field: "remark" },
                            { title: "Type", field: "type" },
                            { title: "Payment Method", field: "paymentMethod" },
                            {
                                title: "Expiry",
                                render: row => {
                                    // console.log(row.expiry_date)
                                    return new Date(row.expiry_date) > new Date() ? "Valid" : "Expired";
                                }
                            },
                            { title: "Amount", field: "amount", render: renderFinanceAmount },
                            {
                                title: "User Referenced",
                                field: "userReference",
                                render: renderUsersName(users)("userReference")
                            },
                            {
                                title: "Created",
                                field: "createdAt",
                                type: "date",
                                render: row => new Date(row.createdAt).toDateString()
                            },
                            {
                                title: "Updated",
                                field: "updatedAt",
                                type: "date",
                                render: row => (row.updatedAt && new Date(row.updatedAt).toLocaleString("en-US")) || ""
                            },
                            {
                                title: "Archived",
                                field: "archivedAt",
                                type: "date",
                                render: row =>
                                    (row.archivedAt && new Date(row.archivedAt).toLocaleString("en-US")) || ""
                            }
                        ]}
                        data={tableData}
                        title=""
                        options={{
                            exportButton: true,
                            exportFileName: "Studio Transactions.csv",
                            exportDelimiter: ",",
                            searchFieldAlignment: "left",
                            search: true
                        }}
                        actions={[
                            {
                                icon: "add",
                                tooltip: "Add Transaction",
                                isFreeAction: true,
                                onClick: () => setShowTransactionForm(true)
                            },
                            {
                                icon: "edit",
                                tooltip: "Modify Transaction",
                                onClick: (event, selectedRow) => {
                                    selectTransactionData(selectedRow);
                                    setShowTransactionForm(true);
                                }
                            }
                        ]}
                        editable={{
                            // onRowUpdate: (newData, oldData) => {
                            //     selectTransactionData(oldData)
                            //     setShowTransactionForm(true)
                            // },
                            onRowDelete: oldData =>
                                firebase
                                    .removeTransaction(oldData)
                                    .then(({ data }) => {
                                        enqueueSnackbar(data, {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            variant: "info"
                                        });
                                        computeDataViz();
                                    })
                                    .catch(({ message }) =>
                                        enqueueSnackbar(message, {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            variant: "error"
                                        })
                                    )
                        }}
                    />
                )}

                <TransactionForm
                    users={users}
                    fetchData={computeDataViz}
                    selectData={selectTransactionData}
                    selectedData={selectedTransactionData}
                    showing={showingTransactionForm}
                    setShow={setShowTransactionForm}
                    defaultRegion={whichRegion()}
                />
            </IonContent>
        </IonPage>
    );
}

export default compose(withFirebase, withUser)(AdminTransactionTable);
