import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React from "react";
import Typography from "@material-ui/core/Typography";

export default props => {
    const {
        submittedAt,
        evaluatedAt,
        evaluationDueDate,
        submissionDueDate,
        score,
        revisedByName,
        revisedAt,
        evaluatedByName,
        revisedDueDate,
        assignedToName,
        attachments,
        attachmentsExpected
    } = props.portfolio;

    return (
        <Stepper activeStep={props.state} style={{ marginTop: "auto" }}>
            <Step>
                <StepLabel
                    optional={
                        submittedAt ? (
                            <Typography variant="caption">Received {new Date(submittedAt).toDateString()}</Typography>
                        ) : (
                            <Typography variant="caption">Due {new Date(submissionDueDate).toDateString()} </Typography>
                        )
                    }
                >
                    {submittedAt
                        ? `${assignedToName} submitted`
                        : `${assignedToName} is submitting ${(attachments && attachments.length) ||
                              0}/${attachmentsExpected} pieces`}
                </StepLabel>
            </Step>
            <Step>
                <StepLabel>Selecting the right teacher</StepLabel>
            </Step>
            <Step>
                <StepLabel
                    optional={
                        evaluatedAt ? (
                            <Typography variant="caption">Evaluated {new Date(evaluatedAt).toDateString()}</Typography>
                        ) : (
                            <Typography variant="caption">Due {new Date(evaluationDueDate).toDateString()}</Typography>
                        )
                    }
                >
                    {submittedAt
                        ? `${evaluatedByName}'s mark ${score}/100`
                        : `${evaluatedByName || "Teacher"} will mark the work`}
                </StepLabel>
            </Step>
            <Step>
                <StepLabel
                    optional={
                        revisedAt ? (
                            <Typography variant="caption">Revised {new Date(revisedAt).toDateString()}</Typography>
                        ) : (
                            <Typography variant="caption">Due {new Date(revisedDueDate).toDateString()}</Typography>
                        )
                    }
                >
                    {revisedAt ? `Revised by ${revisedByName}` : `${revisedByName || "Teacher"} will revise this`}
                </StepLabel>
            </Step>
            <Step>
                <StepLabel>Complete</StepLabel>
            </Step>
        </Stepper>
    );
};
