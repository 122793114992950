export interface Creator {
    createdBy: string;
    createdByName: string;
    createdByAvatar: string;
    createdAt: string;
}

export const initCreator = (creator: any): Creator => ({
    createdAt: new Date().toJSON(),
    createdByName: `${creator.fName} ${creator.lName}`,
    createdBy: creator.uid,
    createdByAvatar: creator.profileAvatar
});

export interface Attachment {
    uid: string;
    url: string;
    title: string;
    description: string;

    creator: Creator;
}

export const initAttachment = (creator: any, url = "", title = "", description = ""): Attachment => ({
    uid: "uid",
    title,
    description,
    url,

    creator: initCreator(creator)
});

export interface Reference {
    uid: string;
    title: string;
    description: string;
    instructions: string;

    attachments: Array<Attachment>;

    tags: Array<Tag>;

    creator: Creator;

    updatedAt: string;
}

export const initReference = (creator: any, title = "", description = "", instructions = ""): Reference => ({
    uid: "uid",
    title,
    description,
    instructions,

    attachments: [],

    tags: [],

    creator: initCreator(creator),

    updatedAt: new Date().toDateString()
});

export interface Tag {
    label: string;
    creator: Creator;
}

export const initTag = (creator: any, label: string = ""): Tag => ({
    label,

    creator: initCreator(creator)
});
