import React from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import { createStyles, Fab, LinearProgress, Theme } from "@material-ui/core";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import Grid from "@material-ui/core/Grid";
import { Add } from "@material-ui/icons";
import { initReference, Reference, Tag } from "./types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReferencePreview from "./ReferencePreview";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: "absolute",
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    })
);

const ReferenceBoard = (props: { firebase: any; user: any; role: any }) => {
    const classes = useStyles();
    const { firebase, user, role } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    React.useEffect(() => {
        (() => fetch())();
    }, []);

    const fetch = async () => {
        let { data } = await firebase.fetchMediaReferenceLibrary();
        setReferences(data.references);
        setAvailableTags(data.tags);
        setLoading(false);
    };

    const [loading, setLoading] = React.useState(true);

    // Listing
    const [references, setReferences] = React.useState<Reference[]>([]);
    const [availableTags, setAvailableTags] = React.useState<Tag[]>([]);

    const onDeleteAt = (index: number) => {
        references.splice(index, 1);
        setReferences([...references]);
    };

    // console.log(references);
    return (
        <IonPage>
            {loading && <LinearProgress />}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Reference Library</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <Grid container spacing={1} justify="center" alignContent="space-around">
                    {references.map((r: Reference, index: number) => (
                        <Grid key={index} item sm={6} md={4} lg={3} xl={2}>
                            <ReferencePreview
                                r={r}
                                index={index}
                                references={references}
                                setReferences={setReferences}
                                loading={loading}
                                setLoading={setLoading}
                                availableTags={availableTags}
                                fetch={fetch}
                                deleteAt={onDeleteAt}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Fab
                    onClick={() => {
                        const newReference = initReference(user);
                        setReferences([newReference, ...references]); // put it on board right away
                    }}
                    className={classes.fab}
                    color="primary"
                    aria-label="add"
                >
                    <Add />
                </Fab>
            </IonContent>
        </IonPage>
    );
};
//@ts-ignore
export default compose(withFirebase, withUser)(ReferenceBoard);
