import React from "react"

export const FirebaseContext = React.createContext(null)
export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>{firebase => <Component {...props} firebase={firebase}/>}</FirebaseContext.Consumer>
)

export const AuthenticationContext = React.createContext(null)
export const withUser = Component => props => (
    <AuthenticationContext.Consumer>{user => <Component {...props} user={user}/>}</AuthenticationContext.Consumer>
)
