import React from "react";
import { compose } from "recompose";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import image from "../../assets/img/bg7.jpg";
import { withFirebase, withUser } from "../../core/Firebase";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { gapi } from "gapi-script";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

export default compose(
    withFirebase,
    withUser,
    withRouter
)(props => {
    const { history, user, firebase } = props;

    const handleGoogleSignIn = () => {
        return firebase.doOnlineSignInWithGooglePopup().then(res => {
            history.push("/");
        });
    };

    if (firebase.isLoggedIn()) {
        if (history.location.pathname.indexOf("/") === -1) {
            history.push("/");
        }
    }

    React.useEffect(() => {
        gapi.signin2.render("my-signin2", {
            scope: "profile ",
            width: 240,
            height: 50,
            longtitle: true,
            theme: "dark"
        });
    }, []);

    return (
        <div
            style={{
                backgroundImage: "url(" + image + ")",
                backgroundSize: "fit",
                backgroundPosition: "top center",
                height: "100%"
            }}
        >
            <Grid container style={{ height: "100%" }} justify="center" alignItems="center">
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Card>
                        <CardHeader>
                            <h4>Login</h4>
                        </CardHeader>
                        <CardContent>
                            <Button fullWidth variant="outlined" href={"https://aureolestudios.ca"}>
                                Go back to main website
                            </Button>

                            <Button fullWidth>
                                <div id="my-signin2" onClick={handleGoogleSignIn} />
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
});
