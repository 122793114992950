import React from "react";
import MaterialTable from "material-table";
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { LinearProgress } from "@material-ui/core";

function TeacherFinances(props) {
    const { firebase, user } = props;

    const [loading, setLoading] = React.useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tableData, setTableData] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const { data: transactions } = await firebase.fetchTransactionsFor();
            setTableData(transactions);
            setLoading(false);
        })();
    }, []);

    return (
        <IonPage>
            {loading && <LinearProgress />}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Payroll</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {tableData && (
                    <MaterialTable
                        columns={[
                            { title: "Status", field: "status" },
                            { title: "Type", field: "type" },
                            { title: "Remark", field: "remark" },
                            { title: "Payment Method", field: "paymentMethod" },
                            { title: "Amount", field: "amount", render: row => `$ ${row.amount}` },
                            { title: "Published", field: "createdAt", type: "date" }
                        ]}
                        data={tableData}
                        title=""
                        options={{
                            exportButton: true,
                            exportFileName: "Aureole - Transactions.csv",
                            exportDelimiter: ",",
                            searchFieldAlignment: "left",
                            search: true
                        }}
                    />
                )}
            </IonContent>
        </IonPage>
    );
}

export default compose(withFirebase, withUser)(TeacherFinances);
