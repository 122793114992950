import React from "react";
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import { v4 as uuid } from "uuid";
import { gapi } from "gapi-script";
import _ from "lodash";
import parallel from "async/parallel";
import imageCompression from "browser-image-compression";
import { initAttachment } from "../../app/ReferenceLibrary/types";
import { uuid4 } from "@capacitor/core/dist/esm/util";
import { makeAutoObservable } from "mobx";

const devConfig = {
    apiKey: "AIzaSyD0at2xoRaY8OfMxh5tLywaHnsy-OnqYhg",
    authDomain: "aureolestudios-31583.firebaseapp.com",
    databaseURL: "https://aureolestudios-31583-default-rtdb.firebaseio.com",
    projectId: "aureolestudios-31583",
    storageBucket: "aureolestudios-31583.appspot.com",
    messagingSenderId: "15641877526",
    appId: "1:15641877526:web:1e8edbd3d8e1b92bbca937",
    clientId: "15641877526-63uf1eq00ofn328cvhpcrlkvag2i479c.apps.googleusercontent.com",
    appVersion: "Aureole Studios development",
    apiAddress: "http://localhost:5005",
    auth: {
        redirect_uri: "http://localhost:5005/as-client/us-central1/oauth2callback"
    }
};

const prodConfig = {
    apiKey: "AIzaSyD0at2xoRaY8OfMxh5tLywaHnsy-OnqYhg",
    authDomain: "aureolestudios-31583.firebaseapp.com",
    databaseURL: "https://aureolestudios-31583-default-rtdb.firebaseio.com",
    projectId: "aureolestudios-31583",
    storageBucket: "aureolestudios-31583.appspot.com",
    messagingSenderId: "15641877526",
    appId: "1:15641877526:web:1e8edbd3d8e1b92bbca937",
    clientId: "15641877526-63uf1eq00ofn328cvhpcrlkvag2i479c.apps.googleusercontent.com",
    appVersion: "Aureole Studios v1.17.594",
    auth: {
        redirect_uri: "https://aureolestudios.ca/api/oauth2callback"
    }
};

// DEV \ PROD
export const APIConfig = process.env.REACT_APP_MODE === "development" ? devConfig : prodConfig;

class Firebase {
    /** Auth API
     *
     * */

    activeUser = null;

    constructor() {
        if (app.apps.length === 0) app.initializeApp(APIConfig);
        this.loadGoogleAuthApi();

        this.auth = app.auth();
        this.auth.googleAuthProvider = new app.auth.GoogleAuthProvider();
        // this.analytics = app.analytics();
        this.db = app.database();
        this.store = app.storage();
        this.firestore = app.firestore();

        this.fn = app.functions();
        if (process.env.REACT_APP_MODE === "development") {
            this.fn.useFunctionsEmulator(APIConfig.apiAddress);
        } else {
            this.fn._url = fn => `https://app.aureolestudios.ca/api/${fn}`;
        }

        this.loading = false;
        this.authCheckComplete = false;

        makeAutoObservable(this);
        this.authCheck();
    }

    authCheck = async () =>
        this.auth.onAuthStateChanged(async user => {
            this.authCheckComplete = true;
            if (user) {
                this.db
                    .ref(`users/${user.uid}`)
                    .once("value")
                    .then(snapshot => {
                        snapshot = snapshot.val();
                        let auth = user.toJSON();

                        // If New User
                        if (!snapshot) {
                            snapshot = {
                                newUser: true,
                                uid: user.uid,
                                email: user.email,
                                fName: auth.displayName,
                                lName: "",
                                profileAvatar: auth.photoURL,
                                role: "student"
                            };
                            this.db.ref(`users/${user.uid}`).set(snapshot);
                        }
                        this.activeUser = snapshot;
                    });
            } else {
                // console.log("We did not authenticate.");
                this.activeUser = null;
            }
        });

    loadGoogleAuthApi = () =>
        gapi.load("auth2", () => {
            this.gapi = gapi.auth2.init({
                apiKey: APIConfig.apiKey,
                client_id: APIConfig.clientId,
                fetch_basic_profile: true,
                ux_mode: "popup",
                response_type: "token id_token",
                immediate: true,
                scope:
                    // Universal online one-time scopes
                    "profile "
            });
        });

    doOnlineSignInWithGooglePopup = async () => {
        const res = await gapi.auth2.getAuthInstance().signIn({
            scope: "profile email"
        });
        const auth = res.getAuthResponse();

        const credential = app.auth.GoogleAuthProvider.credential(null, auth.access_token);

        return this.auth.signInWithCredential(credential);
    };

    giveAdministrativePermissions = async () => {
        const { code } = await gapi.auth2.getAuthInstance().grantOfflineAccess({
            scope:
                "https://www.googleapis.com/auth/spreadsheets " +
                "https://www.googleapis.com/auth/classroom.coursework.students " +
                "https://www.googleapis.com/auth/classroom.announcements " +
                "https://www.googleapis.com/auth/classroom.courses " +
                "https://www.googleapis.com/auth/classroom.rosters " +
                "https://www.googleapis.com/auth/calendar "
        });
        const {
            data: { access_token, refresh_token }
        } = await this.fn.httpsCallable("oauth2callback")({ code });

        return await this.db.ref(`users/${this.activeUser.uid}/refresh_token`).set(refresh_token);
    };

    doSignOut = () => this.auth.signOut();

    isLoggedIn = () => !!this.auth.currentUser;

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    /**
     * User Profile Controller
     * */

    subscribeToUsers = () => this.db.ref("/users");

    getUsers = async () => {
        let users = await this.db.ref("/users").once("value");
        users = users.exportVal();
        return _.map(users, (t, uid) => ({ ...t, uid }));
    };

    getTeachers = async () => {
        // let teachers = await this.db.ref("/users").once("value");
        // teachers = teachers.exportVal();
        // return _.map(teachers, (t, uid) => ({ ...t, uid })).filter(t => t.role.indexOf("teacher") > -1);
    };

    getAdmins = async () => {
        let admins = await this.db.ref("/users").once("value");
        admins = admins.exportVal();
        return _.map(admins, (t, uid) => ({ ...t, uid })).filter(t => t.role && t.role.indexOf("admin") > -1);
    };

    completeOnboarding = form => this.fn.httpsCallable("users-completeOnboarding")(form);

    /**
     * User profile
     * */

    updateUserProfile = form => this.fn.httpsCallable("users-updateProfile")(form);

    removeUserProfile = uid => this.fn.httpsCallable("users-removeProfile")({ uid });

    updateUserProfileAvatar = async (uid = this.auth.currentUser.uid, photoFile) => {
        // upload to the storage bucket
        const snap = await this.store.ref("users/" + uid + "/avatar.jpg").put(photoFile);

        const photoURL = await snap.ref.getDownloadURL();

        this.db.ref("users/" + uid).update({
            profileAvatar: photoURL
        });

        return photoURL;
    };

    /**
     * Performance Reports
     * */

    createPerformanceReport = form => this.fn.httpsCallable("users-createPerformanceReport")(form);

    /** Gallery Controller
     *
     * */
    putGalleryThumbnail = async (uid = this.auth.currentUser.uid, photoFile) => {
        // upload to the storage bucket
        const snap = await this.store.ref("users/" + uid + "/galleries/thumbnails/" + uuid()).put(photoFile);
        const photoURL = await snap.ref.getDownloadURL();
        return photoURL;
    };

    putGalleryContent = async (uid = this.auth.currentUser.uid, gid, photoFile) => {
        const snap = await this.store.ref("users/" + uid + "/galleries/" + gid + "/contents/" + uuid()).put(photoFile);

        const uploadedImage = await snap.ref.getDownloadURL();
        this.db
            .ref("users/" + uid + "/galleries/" + gid + "/contents/")
            .push() // add into series of images
            .set({
                // title,
                src: uploadedImage,
                createdAt: new Date().toString()
            });
    };

    //Get gallery's contents
    subscribeToGalleryContent = (uid = this.auth.currentUser.uid, gid, callback) => {
        return this.db.ref("users/" + uid + "/galleries/" + gid + "/contents/").on("value", callback);
    };

    //Get tiles
    subscribeToGalleries = (uid = this.auth.currentUser.uid, callback) =>
        this.db.ref("users/" + uid + "/galleries").on("value", callback);

    // Creation of a gallery
    putGallery = async (uid = this.auth.currentUser.uid, title, thumbnail) => {
        let galleriesRoot = await this.db.ref("users/" + uid + "/galleries");
        const newGalleryRef = galleriesRoot
            .push() // add into series
            .set({
                title,
                thumbnail,
                createdAt: new Date().toString()
            });
    };

    // Delete content from gallery

    /**
     * Transactions, Transaction Controller
     * */
    fetchTransactionsFor = region => this.fn.httpsCallable("transactions-get")({ region });

    subscribeToTransactions = () => this.db.ref("transactions/");

    createTransaction = form => this.fn.httpsCallable("transactions-create")(form);

    updateTransaction = uid => form => this.fn.httpsCallable("transactions-update")(form);

    updateTransactionStatus = uid => status => {
        if (status === "disabled") {
            //TODO: mark disabled transactions
            return this.db.ref(`finances/transactions/${uid}`).remove();
        }
        return this.db.ref(`finances/transactions/${uid}`).update({
            status,
            updatedAt: new Date().toUTCString()
        });
    };

    removeTransaction = form => this.fn.httpsCallable("transactions-remove")(form);

    /**
     * Classroom Controller
     * */

    fetchClassrooms = () => this.fn.httpsCallable("classroom-getClassrooms")();

    createClassroom = form => this.fn.httpsCallable("classroom-createClassroom")(form);

    updateClassroom = form => this.fn.httpsCallable("classroom-updateClassroom")(form);

    archiveClassroom = form => this.fn.httpsCallable("classroom-archiveClassroom")(form);

    deleteClassroom = form => this.fn.httpsCallable("classroom-deleteClassroom")(form);
    /**
     * Announcements
     * */
    createAnnouncement = form => this.fn.httpsCallable("announcement-create")(form);

    /**
     * Schedule Controller
     * */

    /**
     * Mailer
     * */
    doSendEmail = email => this.fn.httpsCallable("mailer-send")(email);

    doGetRecipientList = () => this.fn.httpsCallable("mailer-listRecipients")();

    /**
     * CourseWork
     * */

    postAssignmentToClassroom = (courseId, form) =>
        this.fn.httpsCallable("courseWork-postToClassroom")({
            courseId,
            form
        });

    /**
     * CourseWork Presets
     * */

    subscribeToCourseWorkAssignmentPresets = () => this.db.ref(`assignmentPresets`);

    createNewCourseWorkAssignmentPreset = form => this.fn.httpsCallable("courseWork-create")(form);

    updateCourseWorkAssignmentPreset = form => this.fn.httpsCallable("courseWork-update")(form);

    removeCourseWorkAssignmentPreset = form => this.fn.httpsCallable("courseWork-remove")(form);

    // createNewCourseWorkPreset = (title, description, assignments) => this.fn.httpsCallable('courseWork-createPreset')
    // ({title, description, assignments})
    //
    // updateCourseWorkPreset = async (preset_id, title, description) => {
    //
    //     return this.fn.httpsCallable('courseWork-updatePreset')({preset_id, title, description})
    //
    // }
    //
    // removeCourseWorkPreset = (title, preset_id) => this.fn.httpsCallable('courseWork-removePreset')({preset_id, title})
    //

    /**
     * Media Reference Library
     * */

    uploadFiles = async files => {
        if (files) {
            const upload = async (uid, img, callback) => {
                const compressed = await imageCompression(img, {
                    maxWidthOrHeight: 720
                });
                const snap = await this.store.ref(`attachments/${uuid()}`).put(compressed);

                const url = await snap.ref.getDownloadURL();

                callback(null, url);
            };
            return await parallel(files.map(img => callback => upload(this.auth.currentUser.uid, img, callback)));
        } else return [];
    };

    uploadAttachments = async (attachments, creator) => {
        if (attachments) {
            const upload = async (uid, img, callback) => {
                const compressed = await imageCompression(img, {
                    maxWidthOrHeight: 720
                });
                const snap = await this.store.ref(`attachments/${uuid()}`).put(compressed);

                const url = await snap.ref.getDownloadURL();

                callback(null, url);
            };
            const uploadedUrls = await parallel(
                attachments.map(img => callback => upload(this.auth.currentUser.uid, img, callback))
            );
            return _.map(uploadedUrls, url => initAttachment(creator, url));
        } else return [];
    };

    fetchMediaReferenceLibrary = () => this.fn.httpsCallable("referenceLibrary-fetch")();

    createMediaReference = async (form, creator) => {
        form.attachments = await this.uploadAttachments(form.files, creator);
        form.uid = uuid4();
        delete form.files;
        return this.fn.httpsCallable("referenceLibrary-create")(form);
    };

    updateMediaReference = reference => this.fn.httpsCallable("referenceLibrary-update")(reference);

    removeMediaReference = reference => this.fn.httpsCallable("referenceLibrary-remove")(reference);

    addReferenceAttachment = async (referenceUID, attachment, file) => {
        const [url] = await this.uploadFiles(file);
        attachment.url = url;
        attachment.uid = uuid4();
        return this.fn.httpsCallable("referenceLibrary-updateAttachment")({ referenceUID, attachment });
    };

    updateReferenceAttachment = (referenceUID, attachment) =>
        this.fn.httpsCallable("referenceLibrary-updateAttachment")({ referenceUID, attachment });

    removeReferenceAttachment = (referenceUID, attachment) =>
        this.fn.httpsCallable("referenceLibrary-removeAttachment")({ referenceUID, attachment });

    /**
     * Portfolio
     * */
    // enrollInPortfolio = () => {
    //     this.db.ref("portfolioSubmissions/").push({
    //         attachments: [],
    //         attachmentsExpected: 10,
    //         program: "Art Foundations",
    //         subject: "Sketching",
    //         assignedTo: "fYzJU1Tf7sVLo4aVFTiBc2sivAr1", // test student
    //         evaluatedBy: "",
    //         revisedBy: "",
    //         state: "",
    //         dueDate: new Date() + 7,
    //         previewImg: ""
    //     });
    // };

    subscribeToStudentsPortfolioSubmissions = () => this.fn.httpsCallable("portfolioSubmission-get")();

    createStudentsPortfolioSubmission = form => this.fn.httpsCallable("portfolioSubmission-create")(form);

    updateStudentsPortfolioSubmission = form => {
        form.updatedAt = new Date();
        return this.fn.httpsCallable("portfolioSubmission-update")(form);
    };

    /*
     * Enrollment mockup
     * */

    enrollIntoProgram = (studentBody, programBody, portfolioSubmissionBody) =>
        this.fn.httpsCallable("enrollment-create")({
            studentBody,
            programBody,
            portfolioSubmissionBody
        });

    /*
     * Programs
     * */
    getPrograms = async () => {
        let programs = await this.db.ref("/programs").once("value");

        programs = programs.val();
        if (!programs) {
            return [
                {
                    uid: "0a7d0c5aa09244c24fac",
                    name: "Art Foundations",
                    description:
                        "Art Foundations is an entry-level class that offers students the chance to work with a variety of media (drawing, painting, sculpture, and ceramics). Students will explore the basic concepts of the elements of art and principles of design.",
                    subjects: [
                        {
                            name: "Drawing",
                            description:
                                "Drawing is a form of visual art in which one uses various drawing instruments to mark paper or another two-dimensional medium.",
                            attachmentsExpected: 3,
                            previewImg:
                                "https://images.unsplash.com/photo-1537884557178-342a575d7d16?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=975&q=80"
                        },
                        {
                            name: "Painting",
                            description:
                                "Painting is the practice of applying paint, pigment, color or other medium to a solid surface",
                            attachmentsExpected: 2,
                            previewImg:
                                "https://images.unsplash.com/photo-1456086272160-b28b0645b729?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2689&q=80"
                        },
                        {
                            name: "Sculpture",
                            description:
                                "Sculpture is the branch of the visual arts that operates in three dimensions. It is one of the plastic arts.",
                            attachmentsExpected: 1,
                            previewImg:
                                "https://images.unsplash.com/photo-1548811579-017cf2a4268b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=935&q=80"
                        }
                    ],
                    previewImg:
                        "https://images.unsplash.com/photo-1525278070609-779c7adb7b71?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1930&q=80"
                }
            ];
        }
        return _.map(programs, (t, uid) => ({ ...t, uid }));
    };
}

export const store = new Firebase();
