import React, { useState } from "react";
import { withFirebase, withUser } from "../../../core/Firebase";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import { IonContent, IonPage } from "@ionic/react";
import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { withRouter } from "react-router-dom";
import { BookmarkOutlined, EmojiEmotionsOutlined, FavoriteOutlined, MoreVert, SendOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {},
    splash: {
        margin: theme.spacing(2, 4)
    },
    splashMedia: {
        height: "15vh"
    },
    feed: {},
    comment: {
        flexGrow: 1
    },
    post: {
        maxWidth: "340px",
        margin: theme.spacing(2, 0)
    },
    announcement: {
        maxWidth: "480px",
        margin: theme.spacing(2, 0)
    },
    createAnnouncement: {
        width: "480px",
        margin: theme.spacing(2, 0),
        flexGrow: 1
    }
}));
export default compose(
    withFirebase,
    withUser,
    withRouter
)((props: any) => {
    const { firebase, user, history } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [creatingANewAnnouncement, setCreatingANewAnnouncement] = useState(false);
    const classes = useStyles();
    return (
        <IonPage>
            <IonContent>
                <Card className={classes.splash}>
                    <CardMedia
                        className={classes.splashMedia}
                        image="https://images.unsplash.com/photo-1507842217343-583bb7270b66?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2653&q=80"
                    />
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Subject: Digital Design Arts
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Animation program
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Portfolio{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Located in Markham
                        </Typography>
                    </CardContent>
                </Card>

                <Box display="flex" flexDirection="column" className={classes.feed} alignItems="center">
                    {/*Announcement*/}
                    <Card className={classes.createAnnouncement}>
                        <CardActionArea
                            disabled={creatingANewAnnouncement}
                            onClick={() => setCreatingANewAnnouncement(true)}
                        >
                            <CardHeader
                                avatar={<Avatar>J</Avatar>}
                                title="Jerry ding"
                                subheader="Post an announcement"
                            />
                        </CardActionArea>
                        {creatingANewAnnouncement && (
                            <CardContent>
                                <Box display="flex">
                                    <TextField
                                        style={{ flexGrow: 1 }}
                                        placeholder="Announce something to your class..."
                                        rows={4}
                                        multiline
                                        variant="filled"
                                    />
                                    <IconButton>
                                        <SendOutlined />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        )}
                    </Card>

                    {/*Teacher's Announcement */}
                    <Card className={classes.announcement}>
                        <CardHeader
                            avatar={<Avatar>J</Avatar>}
                            action={
                                <IconButton aria-label="settings">
                                    <MoreVert />
                                </IconButton>
                            }
                            title="Jerry ding"
                            subheader="September 14, 2021"
                        />
                        <CardContent>
                            <Box display="flex">
                                <Typography component="p">
                                    Our campus at Markham is now officially open 3 days in a week: Thursday, Saturday
                                    and Sunday. We welcome every creative to come join us and do the art. All students
                                    will receive enough attention and we'll have great times.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>

                    {/*Student's post*/}
                    <Card className={classes.post}>
                        <CardHeader
                            avatar={<Avatar>W</Avatar>}
                            action={
                                <IconButton aria-label="settings">
                                    <MoreVert />
                                </IconButton>
                            }
                            title="Wenxuan Liu"
                            subheader="Doing my homework..."
                        />
                        <CardMedia
                            className={classes.splashMedia}
                            image="https://images.unsplash.com/photo-1456086272160-b28b0645b729?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Nnx8cGFpbnRpbmd8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"
                        />
                        <CardContent>
                            <IconButton>
                                <FavoriteOutlined />
                            </IconButton>
                            <IconButton>
                                <BookmarkOutlined />
                            </IconButton>
                            <Typography component="p">
                                Liked by <a>@timqin</a> and <a>@jerryding</a>
                            </Typography>

                            <Box display="flex" justifyContent="center">
                                <Typography align="center" component="a">
                                    Show all comments...
                                </Typography>
                            </Box>

                            <Box display="flex">
                                <Typography component="a">jerryding </Typography>
                                <Typography component="p">Great. Now what kind of paints are those.</Typography>
                            </Box>

                            <Box display="flex">
                                <Typography component="a">timqin </Typography>
                                <Typography component="p">👏👏👏👏👏</Typography>
                            </Box>

                            <Box display="flex">
                                <IconButton>
                                    <EmojiEmotionsOutlined />
                                </IconButton>
                                <TextField className={classes.comment} placeholder="Add a comment..." />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </IonContent>
        </IonPage>
    );
});
