import React, { useState } from "react";
import { withFirebase, withUser } from "../../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import {
    initialStatePiece,
    initialStatePortfolioSubmission,
    Piece,
    PortfolioSubmission,
    SubmissionStatus
} from "../../../assets/types";
import { CardActions, CardHeader, CardMedia } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { SketchField, Tools } from "react-sketch";
import EditPiece from "../components/EditPiece";
import LinearProgress from "@material-ui/core/LinearProgress";
import Stepper from "../components/Stepper";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import FullScreenPreview from "../../../components/FullScreenPreview";

const PortfolioReview = (props: {
    user?: any;
    firebase?: any;
    selectedData?: PortfolioSubmission;
    setShow?: any;
    selectData?: any;
    showing?: boolean;
    fetch: any;
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase } = props;

    const [loading, setLoading] = useState(false);

    // PortfolioSubmission state
    React.useEffect(() => props.selectedData && setPortfolio(props.selectedData), [props.selectedData]);
    const [portfolio, setPortfolio] = React.useState<PortfolioSubmission>(initialStatePortfolioSubmission);

    // Piece state
    const [showingEditingDialog, showEditingDialog] = React.useState(false);
    const [piece, setPiece] = useState<Piece>(initialStatePiece);

    const onMarkedPieceSave = (piece: Piece) => {
        const attachments = portfolio!.attachments;
        const index = attachments.findIndex(a => piece.originalImg === a.originalImg);

        attachments[index].updatedAt = new Date();
        attachments[index] = piece;

        console.log("Persisting marked piece", attachments[index]);
        setPortfolio({
            ...portfolio,
            attachments
        });

        onSubmit(false);
    };

    // Dialog Actions
    const onSubmit = (close: boolean = true) => {
        setLoading(true);

        if (close) portfolio.state = SubmissionStatus.ADMIN_REVISING;

        updatePortfolio(portfolio, close);
    };

    const updatePortfolio = (portfolio: PortfolioSubmission, close = true) => {
        return firebase
            .updateStudentsPortfolioSubmission(portfolio)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                setLoading(false);
                close && onClose();
            })
            .catch((err: any) => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onClose = () => {
        props.setShow(false);
        props.selectData(initialStatePortfolioSubmission);
        props.fetch();
    };
    // Submission for revision
    const [showingRevisionDialog, showRevisionDialog] = React.useState(false);
    const completeEvaluation = () => {
        portfolio.state = SubmissionStatus.ADMIN_REVISING;
        updatePortfolio(portfolio);
        showRevisionDialog(false);
    };

    // Fullscreen piece preview
    const [fullScreenPiecePreviewIndex, setFullScreenPiecePreviewIndex] = React.useState(-1);

    const {
        program,
        subject,
        attachments,
        attachmentsExpected,
        state,
        submissionDueDate,
        evaluationDueDate,
        score,
        teacherFeedback
    } = portfolio;
    return (
        <>
            <Dialog fullScreen scroll="paper" onClose={onClose} open={!!props.showing}>
                <DialogTitle>
                    {program}: {subject}
                    <IconButton
                        style={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            color: "grey"
                        }}
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {loading && <LinearProgress />}

                    <Grid container spacing={1}>
                        {attachments &&
                            attachments.map((piece: Piece, index: number) => {
                                return (
                                    <Grid xs={6} sm={4} md={4} lg={3} item key={index}>
                                        <Card>
                                            <CardHeader title={piece.title} />
                                            <CardMedia image={piece.originalImg} style={{ height: "140px" }} />
                                            <CardContent>
                                                <TextField
                                                    fullWidth
                                                    helperText="Teacher's feedback to student"
                                                    placeholder={"Devil is in the details..."}
                                                    value={piece.givenFeedback}
                                                    multiline
                                                    rowsMax={6}
                                                    onChange={({ target: { value } }) => {
                                                        const attachments = portfolio.attachments;
                                                        attachments[index].givenFeedback = value;
                                                        attachments[index].updatedAt = new Date();

                                                        setPortfolio({
                                                            ...portfolio,
                                                            attachments
                                                        });
                                                    }}
                                                />
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    onClick={() => {
                                                        setPiece(portfolio!.attachments[index]);
                                                        showEditingDialog(true);
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button onClick={() => setFullScreenPiecePreviewIndex(index)}>
                                                    Fullscreen
                                                </Button>
                                            </CardActions>
                                            <FullScreenPreview
                                                fullScreenPiecePreviewIndex={fullScreenPiecePreviewIndex}
                                                setFullScreenPiecePreviewIndex={setFullScreenPiecePreviewIndex}
                                                piece={piece}
                                                index={index}
                                            />
                                        </Card>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </DialogContent>

                <Stepper state={state} portfolio={portfolio} />

                <DialogActions>
                    <Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={() => onSubmit(false)}
                        disabled={loading || state === SubmissionStatus.COMPLETE}
                    >
                        Save changes
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => showRevisionDialog(true)}
                        disabled={loading || state === SubmissionStatus.COMPLETE}
                    >
                        Complete Evaluation
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} open={showingRevisionDialog} onClose={() => showRevisionDialog(false)}>
                <DialogTitle>Submit evaluation</DialogTitle>
                <DialogContent>
                    <TextField
                        margin={"normal"}
                        fullWidth
                        placeholder={"50 / 100"}
                        value={score}
                        type="number"
                        InputProps={{
                            inputProps: {
                                max: 100,
                                min: 0
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            //@ts-ignore
                            setPortfolio({ ...portfolio, score: value });
                        }}
                    />
                    <TextField
                        margin={"normal"}
                        fullWidth
                        rows={3}
                        multiline
                        placeholder={"This work shows strong skill and immaculate accuracy, however..."}
                        rowsMax={15}
                        value={teacherFeedback}
                        onChange={({ target: { value } }) => setPortfolio({ ...portfolio, teacherFeedback: value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={completeEvaluation}
                        disabled={loading}
                    >
                        Submit for review
                    </Button>
                </DialogActions>
            </Dialog>

            <EditPiece
                selectData={setPiece}
                selectedData={piece}
                setShow={showEditingDialog}
                showing={showingEditingDialog}
                onMarkedPieceSave={onMarkedPieceSave}
            />
        </>
    );
};

export default withFirebase(withUser(PortfolioReview));
