/*eslint no-unused-vars: 0, no-console: 0*/

import React from "react";
import { CompactPicker } from "react-color";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";

import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { SketchField, Tools } from "react-sketch";
import Grid from "@material-ui/core/Grid";
import { Gesture, PanTool, TouchApp } from "@material-ui/icons";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";

class SketchFieldDemo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lineWidth: 8,
            lineColor: "black",
            fillColor: "white",
            backgroundColor: "transparent",
            tool: Tools.Pencil,
            drawings: [],
            // stretched: true,
            // stretchedX: true,
            // stretchedY: true,
            originX: "left",
            originY: "top",
            canUndo: false,
            canRedo: false,

            text: ""
        };
    }

    _selectTool = event => {
        this.setState({
            tool: event
            // enableRemoveSelected: event.target.value === Tools.Select,
            // enableCopyPaste: event.target.value === Tools.Select
        });
    };

    _save = () => {
        const markedImg = this._sketch.toJSON();
        markedImg["objects"] = markedImg["objects"].map(o => {
            if (o.type === "path") o.fill = false;

            return o;
        });

        this.props.onMarkedPieceSave({
            ...this.props.piece,
            markedImg
        });

        this.props.onClose();
    };

    _download = () => {
        console.error("Unimplemented");

        // console.save(this._sketch.toDataURL(), 'toDataURL.txt');
        // console.save(JSON.stringify(this._sketch.toJSON()), 'toDataJSON.txt');

        /*eslint-enable no-console*/
    };

    _removeMe = index => {
        let drawings = this.state.drawings;
        drawings.splice(index, 1);
        this.setState({ drawings: drawings });
    };

    _undo = () => {
        this._sketch.undo();
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo()
        });
    };

    _redo = () => {
        this._sketch.redo();
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo()
        });
    };

    _clear = () => {
        this._sketch.clear();
        this._sketch.setBackgroundFromDataUrl("");
        this.setState({
            controlledValue: null,
            backgroundColor: "transparent",
            fillWithBackgroundColor: false,
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo()
        });
    };

    _removeSelected = () => {
        this._sketch.removeSelected();
    };

    _onSketchChange = () => {
        let prev = this.state.canUndo;
        let now = this._sketch.canUndo();
        if (prev !== now) {
            this.setState({ canUndo: now });
        }
    };

    _addText = () => this._sketch.addText(this.state.text);

    componentDidMount = () => {
        (function(console) {
            console.save = function(data, filename) {
                if (!data) {
                    console.error("Console.save: No data");
                    return;
                }
                if (!filename) filename = "console.json";
                if (typeof data === "object") {
                    data = JSON.stringify(data, undefined, 4);
                }
                var blob = new Blob([data], { type: "text/json" }),
                    e = document.createEvent("MouseEvents"),
                    a = document.createElement("a");
                a.download = filename;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            };
        })(console);

        // Set only in case there's no saved version of this
        if (!this.props.piece.markedImg && this.props.piece.originalImg) {
            // console.log("Creating new sketch...");
            this._sketch.setBackgroundFromDataUrl(this.props.piece.originalImg);
        }
        if (this.props.piece.markedImg) {
            // console.log("Loading existing sketch...");
            this._sketch.fromJSON(this.props.piece.markedImg);
        }
    };

    render = () => {
        return (
            <Grid container style={{ height: "100%" }} spacing={0} justify={"space-between"}>
                <Grid item sm={9} md={9}>
                    <SketchField
                        name="sketch"
                        ref={c => (this._sketch = c)}
                        lineColor={this.state.lineColor}
                        lineWidth={this.state.lineWidth}
                        fillColor={this.state.fillWithColor ? this.state.fillColor : "transparent"}
                        forceValue
                        onChange={this._onSketchChange}
                        tool={this.state.tool}
                        height={"100%"}
                    />
                </Grid>
                <Grid item sm={3} md={3}>
                    <Card>
                        <CardHeader title="Tools" />
                        <IconButton color="primary" disabled={!this.state.canUndo} onClick={this._undo}>
                            <UndoIcon />
                        </IconButton>
                        <IconButton color="primary" disabled={!this.state.canRedo} onClick={this._redo}>
                            <RedoIcon />
                        </IconButton>
                        <IconButton onClick={e => this._sketch.zoom(1.25)}>
                            <ZoomInIcon />
                        </IconButton>
                        <IconButton onClick={e => this._sketch.zoom(0.8)}>
                            <ZoomOutIcon />
                        </IconButton>
                        {/*<IconButton color="primary" onClick={this._download}>*/}
                        {/*    <DownloadIcon />*/}
                        {/*</IconButton>*/}
                        <List>
                            <MenuItem
                                selected={this.state.tool === Tools.Pan}
                                key="Pan"
                                onClick={() => this._selectTool(Tools.Pan)}
                            >
                                <PanTool />
                                Pan
                            </MenuItem>
                            <MenuItem
                                key="Select"
                                selected={this.state.tool === Tools.Select}
                                onClick={() => this._selectTool(Tools.Select)}
                            >
                                <TouchApp />
                                Select element
                            </MenuItem>
                            <MenuItem
                                key="Pencil"
                                selected={this.state.tool === Tools.Pencil}
                                onClick={() => this._selectTool(Tools.Pencil)}
                            >
                                <Gesture />
                                Drawing Pencil
                            </MenuItem>
                            <MenuItem
                                key="Line"
                                selected={this.state.tool === Tools.Line}
                                onClick={() => this._selectTool(Tools.Line)}
                                key="Line"
                            >
                                Line
                            </MenuItem>
                            <MenuItem
                                selected={this.state.tool === Tools.Rectangle}
                                onClick={() => this._selectTool(Tools.Rectangle)}
                                key="Rectangle"
                            >
                                Rectangle
                            </MenuItem>
                            <MenuItem
                                selected={this.state.tool === Tools.Circle}
                                onClick={() => this._selectTool(Tools.Circle)}
                                key="Circle"
                            >
                                Circle
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    this._sketch.addImg(
                                        "https://icon-library.com/images/copyright-icon-png/copyright-icon-png-18.jpg"
                                    )
                                }
                                key="Watermark"
                            >
                                Watermark
                            </MenuItem>
                        </List>
                        <CompactPicker
                            color={this.state.lineColor}
                            onChange={color => this.setState({ lineColor: color.hex })}
                        />

                        <CardContent>
                            <small id="slider">Line width: {this.state.lineWidth}</small>
                            <Slider
                                aria-labelledby="slider"
                                step={1}
                                min={1}
                                label={"Line Weight"}
                                max={20}
                                value={this.state.lineWidth}
                                onChange={(e, v) => this.setState({ lineWidth: v })}
                            />
                            <TextField
                                margin={"normal"}
                                fullWidth
                                placeholder={"This requires more detail..."}
                                helperText="Comment on the sketch"
                                onChange={e => this.setState({ text: e.target.value })}
                                value={this.state.text}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton color="primary" onClick={this._addText}>
                                            <AddIcon />
                                        </IconButton>
                                    )
                                }}
                            />

                            <TextField
                                margin={"normal"}
                                fullWidth
                                helperText="Provide feedback for student to improve upon"
                                placeholder={"Devil is in the details..."}
                                value={this.props.piece.givenFeedback}
                                multiline
                                rows={3}
                                onChange={({ target: { value } }) => {
                                    this.props.setPiece({ ...this.props.piece, givenFeedback: value });
                                }}
                            />

                            <Button fullWidth variant="contained" color="primary" onClick={this._save}>
                                <SaveIcon /> Save & Close
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    };
}

export default SketchFieldDemo;
