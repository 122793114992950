import React, { useState } from "react";
import { withFirebase, withUser } from "../../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import {
    initialStatePortfolioSubmission,
    initialStateProgram,
    Program,
    Subject,
    SubmissionStatus
} from "../../../assets/types";
import { CardHeader, CardMedia } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// @ts-ignore
import { SketchField, Tools } from "react-sketch";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import { withRouter } from "react-router-dom";

const ProgramDetailsDialog = (props: {
    user?: any;
    firebase?: any;
    selectedData?: Program;
    setShow?: any;
    selectData?: any;
    showing?: boolean;
    fetch: any;
    history: any;
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase } = props;

    const [loading, setLoading] = useState(false);

    // PortfolioSubmission state
    React.useEffect(() => props.selectedData && setProgram(props.selectedData), [props.selectedData]);
    const [program, setProgram] = React.useState<Program>(initialStateProgram);

    // Piece state
    const [showing, show] = React.useState(false);

    const [form, setform] = React.useState<any>({});

    // Dialog Actions
    const assignTeacher = () => {
        show(false);
        updatePortfolio({
            ...program,
            evaluatedBy: form.evaluatedBy,
            evaluatedByName: form.evaluatedByName,
            evaluationDueDate: (form.evaluationDueDate && form.evaluationDueDate.toJSON()) || new Date().toJSON(),
            state: SubmissionStatus.TEACHER_EVALUATING
        });
    };

    const completeRevision = () => {
        updatePortfolio({
            ...program,
            state: SubmissionStatus.COMPLETE
        });
    };

    const updatePortfolio = (portfolio: any) => {
        setLoading(true);
        return firebase
            .updateStudentsPortfolioSubmission(portfolio)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                setLoading(false);
                onClose();
            })
            .catch((err: any) => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onClose = () => {
        props.setShow(false);
        props.selectData(initialStatePortfolioSubmission);
        props.fetch();
    };

    // Fullscreen piece preview
    const [fullScreenPiecePreviewIndex, setFullScreenPiecePreviewIndex] = React.useState(-1);

    // TEacher assignment
    const [teachers, setTeachers] = useState([]);
    React.useEffect(() => {
        (async () => {
            const teachers = await firebase.getTeachers();
            setTeachers(teachers);
        })();
    }, []);

    const { name, description, previewImg, subjects, uid } = program;

    return (
        <>
            <Dialog fullScreen scroll="paper" onClose={onClose} open={!!props.showing}>
                <DialogTitle>
                    {name}
                    <IconButton
                        style={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            color: "grey"
                        }}
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {loading && <LinearProgress />}

                    <Grid container spacing={1}>
                        {subjects &&
                            subjects.map((subject: Subject, index: number) => {
                                return (
                                    <Grid xs={12} sm={6} md={4} lg={3} item key={index}>
                                        <Card>
                                            <CardHeader title={subject.name} />
                                            <CardMedia
                                                image={subject.previewImg}
                                                style={{ height: "340px" }}
                                                onClick={() => setFullScreenPiecePreviewIndex(index)}
                                            />
                                            <CardContent>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    multiline
                                                    rowsMax={6}
                                                    value={subject.description}
                                                    helperText={"Description"}
                                                />
                                                <TextField
                                                    type="number"
                                                    value={subject.attachmentsExpected}
                                                    helperText={"Number of pieces required for Portfolio"}
                                                />
                                            </CardContent>
                                        </Card>
                                        {/*<FullScreenPreview*/}
                                        {/*    fullScreenPiecePreviewIndex={fullScreenPiecePreviewIndex}*/}
                                        {/*    setFullScreenPiecePreviewIndex={setFullScreenPiecePreviewIndex}*/}
                                        {/*    subject={subject}*/}
                                        {/*    index={index}*/}
                                        {/*/>*/}
                                    </Grid>
                                );
                            })}
                    </Grid>
                </DialogContent>
            </Dialog>

            {/*<Dialog open={showing}>*/}
            {/*    <DialogTitle>Assign teacher to evaluate this portfolio</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <Autocomplete*/}
            {/*            disabled={loading}*/}
            {/*            options={teachers}*/}
            {/*            // value={() => {*/}
            {/*            //     // @ts-ignore*/}
            {/*            //     const [evaluatedByUser] = teachers.find((u: any) => u.uid === form.evaluatedBy);*/}
            {/*            //     return evaluatedByUser;*/}
            {/*            // }}*/}
            {/*            onChange={(e, newValue) => {*/}
            {/*                setform({*/}
            {/*                    ...form,*/}
            {/*                    evaluatedBy: newValue.uid,*/}
            {/*                    evaluatedByName: `${newValue.fName} ${newValue.lName}`*/}
            {/*                });*/}
            {/*            }}*/}
            {/*            getOptionLabel={(u: any) => {*/}
            {/*                if (!u) return "";*/}
            {/*                return `${u.fName} ${u.lName} (${u.role}) ${u.email}`;*/}
            {/*            }}*/}
            {/*            renderInput={params => <TextField {...params} label="Referenced User..." variant="outlined" />}*/}
            {/*        />*/}

            {/*        <DateTimePicker*/}
            {/*            fullWidth*/}
            {/*            required*/}
            {/*            label="Evaluation Due"*/}
            {/*            value={form.evaluationDueDate}*/}
            {/*            onChange={evaluationDueDate => setform({ ...form, evaluationDueDate })}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button variant="contained" fullWidth color="primary" onClick={assignTeacher} disabled={loading}>*/}
            {/*            Assign*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </>
    );
};

export default withRouter(withFirebase(withUser(ProgramDetailsDialog)));
