import IconButton from "@material-ui/core/IconButton";
import { Attachment, initAttachment, Reference, Tag } from "./types";
import {
    DeleteForeverOutlined,
    EditOutlined,
    FavoriteOutlined,
    MoreVertOutlined,
    RedoOutlined,
    ShareOutlined
} from "@material-ui/icons";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { Card, CardActions, MenuItem, TextField, Tooltip } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { DropzoneArea } from "material-ui-dropzone";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import React from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";

const AttachmentPreview = (props: {
    index: number;
    firebase: any;
    user: any;
    role: any;
    fetch: any;
    availableTags: Tag[];
    loading: boolean;
    setLoading: any;
    attachments: Attachment[];
    setAttachments: any;
    references: Reference[];
    selectedReference: Reference;
    a: Attachment;
}) => {
    const {
        index,
        firebase,
        user,
        fetch,
        availableTags,
        loading,
        attachments,
        setLoading,
        setAttachments,
        references,
        selectedReference,
        a
    } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

    const [isCreatingNew, createNew] = React.useState<boolean>(false);
    const [isEditing, edit] = React.useState<boolean>(false);
    const [selectedAttachment, selectAttachment] = React.useState<Attachment>(initAttachment(user));
    const [files, setFiles] = React.useState<File[]>();
    React.useEffect(() => {
        (() => {
            if (a.uid === "uid") {
                edit(true);
                createNew(true);
            }
            selectAttachment(props.a);
        })();
    }, [props.a]);

    const onCreateAttachment = (index: number) => {
        setLoading(true);

        firebase
            .addReferenceAttachment(selectedReference.uid, selectedAttachment, files)
            .then((success: any) => {
                enqueueSnackbar(success.data.message, {
                    variant: "success",
                    persist: false
                });
                setLoading(false);
                createNew(false);
                edit(false);
                // @ts-ignore
                attachments[index] = success.data.attachment;
                fetch();
            })
            .catch((e: any) => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onDeleteAttachment = (index: number) => {
        setLoading(true);
        firebase
            .removeReferenceAttachment(selectedReference.uid, selectedAttachment)
            .then((success: any) => {
                enqueueSnackbar(success.data, {
                    variant: "success",
                    persist: false
                });
                setLoading(false);
                edit(false);

                attachments.splice(index, 1);
                setAttachments([...attachments]);
                fetch();
            })
            .catch((e: any) => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };
    const onUpdateAttachment = async (index: number) => {
        setLoading(true);

        firebase
            .updateReferenceAttachment(selectedReference.uid, selectedAttachment)
            .then((success: any) => {
                enqueueSnackbar(success.data.message, {
                    variant: "success"
                });
                setLoading(false);
                edit(false);

                // @ts-ignore
                attachments[index] = selectedAttachment;
                setAttachments(attachments);
                fetch();
            })
            .catch((e: any) => {
                enqueueSnackbar(e.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };

    return (
        <Card>
            {isCreatingNew ? (
                <DropzoneArea filesLimit={1} dropzoneText={""} onChange={files => setFiles(files)} />
            ) : (
                <CardMedia component="img" image={selectedAttachment.url} title={selectedAttachment.title} />
            )}
            <CardContent>
                {isEditing ? (
                    <TextField
                        disabled={loading}
                        fullWidth
                        placeholder={"Give a name"}
                        value={selectedAttachment.title}
                        onChange={({ target: { value } }) =>
                            selectAttachment({
                                ...selectedAttachment,
                                title: value
                            })
                        }
                    />
                ) : (
                    <Typography gutterBottom variant="h5" component="h2">
                        {selectedAttachment.title}
                    </Typography>
                )}

                {isEditing ? (
                    <TextField
                        disabled={loading}
                        fullWidth
                        multiline
                        rows={2}
                        rowsMax={4}
                        placeholder={"Describe it"}
                        value={selectedAttachment.description}
                        onChange={({ target: { value } }) =>
                            selectAttachment({
                                ...selectedAttachment,
                                description: value
                            })
                        }
                    />
                ) : (
                    <Typography variant="body2" color="textSecondary" component="p">
                        {selectedAttachment.description}
                    </Typography>
                )}
            </CardContent>

            <CardActions disableSpacing>
                {!isEditing && !isCreatingNew && (
                    <>
                        <Tooltip title="Unimplemented">
                            <IconButton aria-label="add to favorites">
                                <FavoriteOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Unimplemented">
                            <IconButton aria-label="share">
                                <ShareOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                )}

                {isEditing && isCreatingNew && (
                    <Button fullWidth variant="outlined" onClick={() => onCreateAttachment(index)}>
                        Create
                    </Button>
                )}
                {isEditing && !isCreatingNew && (
                    <Button fullWidth variant="outlined" onClick={() => onUpdateAttachment(index)}>
                        Update
                    </Button>
                )}
                {isEditing ? (
                    <>
                        <IconButton
                            onClick={() => {
                                if (isCreatingNew) {
                                    attachments.splice(index, 1);
                                    createNew(false);
                                    edit(false);
                                } else {
                                    edit(false);
                                }
                            }}
                        >
                            <RedoOutlined />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                key={1}
                                onClick={() => {
                                    edit(true);
                                    popupState.close();
                                }}
                            >
                                <EditOutlined /> Edit
                            </MenuItem>
                            {!isCreatingNew && (
                                <MenuItem
                                    key={0}
                                    onClick={() => {
                                        onDeleteAttachment(index);
                                        popupState.close();
                                    }}
                                >
                                    <DeleteForeverOutlined color="error" /> Delete
                                </MenuItem>
                            )}
                        </Menu>
                        <IconButton {...bindTrigger(popupState)} aria-label="settings">
                            <MoreVertOutlined />
                        </IconButton>
                    </>
                )}
            </CardActions>
        </Card>
    );
};

export default withUser(withFirebase(AttachmentPreview));
