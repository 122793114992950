import React from "react";
import { withFirebase } from "../../core/Firebase";
import { compose } from "recompose";
import MaterialTable from "material-table";
import UserProfileReview from "./UserProfileReview";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import MenuItem from "@material-ui/core/MenuItem";
import { renderRegion, renderStudentType } from "../../core/helperFunctions";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ComposeEmailDialog from "./ComposeEmailDialog";
import Select from "@material-ui/core/Select";

function UserTable(props) {
    const { firebase, user, role } = props;
    const [users, setUsers] = React.useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [reviewingProfile, reviewProfile] = React.useState(false);
    const [composingEmail, composeEmail] = React.useState(false);

    const [chosen, setChosen] = React.useState([]);
    // Tab Selector 0, 1 ,2

    // Fetch data
    React.useEffect(() => {
        (() => {
            props.firebase.subscribeToUsers().on("value", snapshot => {
                if (!snapshot.exists) return console.error("No Data received");

                let flattened = [];
                snapshot.forEach(snap => {
                    let uid = snap.key;
                    snap = snap.val();

                    // if (typeof snap.studentType === 'string')
                    //     snap.studentType = snap.studentType.split(',')

                    flattened.push({
                        uid,
                        ...snap
                    });
                });

                setUsers(flattened);
            });
        })();
    }, []);

    const computedColumns = () => {
        switch (role) {
            case 0: {
                return [
                    {
                        title: "Student Type",
                        field: "studentType",
                        render: renderStudentType,
                        editComponent: props => {
                            let { value, onChange } = props;
                            if (!Array.isArray(value)) {
                                value = [value];
                            }
                            return (
                                <Select
                                    name="studentType"
                                    fullWidth
                                    multiple
                                    value={value || []}
                                    onChange={event => onChange(event.target.value)}
                                >
                                    <MenuItem value="online">Online</MenuItem>
                                    <MenuItem value="onCampus">On Campus</MenuItem>
                                </Select>
                            );
                        }
                    },
                    {
                        title: "Region",
                        field: "region",
                        render: renderRegion,
                        editComponent: props => {
                            let { value, onChange } = props;
                            if (!Array.isArray(value)) {
                                value = [value];
                            }
                            return (
                                <Select
                                    name="region"
                                    fullWidth
                                    multiple
                                    value={value || []}
                                    onChange={(event, _) => onChange(event.target.value)}
                                >
                                    <MenuItem value="Markham">Markham</MenuItem>
                                    <MenuItem value="Oakville">Oakville</MenuItem>
                                </Select>
                            );
                        }
                    },
                    { title: "First Name", field: "fName" },
                    { title: "Last Name", field: "lName" },
                    { title: "Email", field: "email" },
                    { title: "Phone", field: "phoneNum" }
                ];
                break;
            }
            case 1: {
                return [
                    {
                        title: "Region",
                        field: "region",
                        render: renderRegion,
                        editComponent: props => {
                            let { value, onChange } = props;
                            if (!Array.isArray(value)) {
                                value = [value];
                            }
                            return (
                                <Select
                                    name="region"
                                    fullWidth
                                    multiple
                                    value={value || []}
                                    onChange={(event, _) => onChange(event.target.value)}
                                >
                                    <MenuItem value="Markham">Markham</MenuItem>
                                    <MenuItem value="Oakville">Oakville</MenuItem>
                                </Select>
                            );
                        }
                    },
                    { title: "First Name", field: "fName" },
                    { title: "Last Name", field: "lName" },
                    { title: "Email", field: "email" },
                    { title: "Phone", field: "phoneNum" }
                ];

                break;
            }
            case 2: {
                return [
                    {
                        title: "Region",
                        field: "region",
                        render: renderRegion,
                        editComponent: props => {
                            let { value, onChange } = props;
                            if (!Array.isArray(value)) {
                                value = [value];
                            }
                            return (
                                <Select
                                    name="region"
                                    fullWidth
                                    multiple
                                    value={value || []}
                                    onChange={(event, _) => onChange(event.target.value)}
                                >
                                    <MenuItem value="Markham">Markham</MenuItem>
                                    <MenuItem value="Oakville">Oakville</MenuItem>
                                </Select>
                            );
                        }
                    },
                    { title: "First Name", field: "fName" },
                    { title: "Last Name", field: "lName" },
                    { title: "Email", field: "email" },
                    { title: "Phone", field: "phoneNum" }
                ];
                break;
            }
        }
    };

    const computedTableData = () => {
        switch (role) {
            case 0: {
                return users.filter(u => u.role === "student");
            }
            case 1: {
                return users.filter(u => u.role === "teacher");
            }
            case 2: {
                return users.filter(u => u.role === "admin");
            }
        }
    };

    if (!users) {
        return <CircularProgress />;
    }

    const handleSendEmail = (e, rowData) => {
        setChosen(rowData);

        composeEmail(true);
    };

    return (
        <>
            <MaterialTable
                columns={computedColumns()}
                data={computedTableData()}
                title=""
                options={{
                    actionsColumnIndex: -1,
                    selection: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50]
                }}
                actions={[
                    {
                        icon: AccountCircleIcon,
                        tooltip: "Open User Profile",
                        position: "row",
                        onClick: (event, rowData) => {
                            setChosen(rowData);
                            reviewProfile(rowData);
                        }
                    },
                    {
                        icon: MailOutlineIcon,
                        tooltip: "Send Email",
                        onClick: handleSendEmail,
                        isFreeAction: false
                    },
                    {
                        icon: MailOutlineIcon,
                        tooltip: "Send Email",
                        onClick: handleSendEmail,
                        isFreeAction: true
                    }
                ]}
                editable={{
                    onRowDelete: ({ uid }) => {
                        return firebase
                            .removeUserProfile(uid)
                            .then(({ data }) => {
                                enqueueSnackbar(data, {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    variant: "success"
                                });
                            })
                            .catch(err => {
                                enqueueSnackbar(err.message, {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    variant: "error"
                                });
                            });
                    },
                    onRowUpdate: (newData, oldData) =>
                        props.firebase
                            .updateUserProfile(newData)
                            .then(success => {
                                enqueueSnackbar(success.data, {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    variant: "success"
                                });
                            })
                            .catch(err => {
                                enqueueSnackbar(err.message, {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    variant: "error"
                                });
                            })
                }}
            />
            <UserProfileReview showing={!!reviewingProfile} setShow={reviewProfile} selectedData={reviewingProfile} />
            <ComposeEmailDialog
                showing={composingEmail}
                setShow={composeEmail}
                selectedData={chosen}
                selectData={setChosen}
                users={users}
            />
        </>
    );
}

export default compose(withFirebase)(UserTable);
