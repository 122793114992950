import React from "react"
import {withRouter} from "react-router-dom"
import {withUser} from "../../core/Firebase"
import Library from "./ReferenceBoard"

function Router(props) {
    const {role} = props.user

    return <Library/>
}

export default withRouter(withUser(Router))
