import React from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";
import { useSnackbar } from "notistack";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { Card, CardActionArea, CardContent, CardMedia, LinearProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { withRouter } from "react-router-dom";

interface ReferencedInstructor {
    courseId: string;
    profile: {
        emailAddress: string;
        id: string;
        name: {
            familyName: string;
            fullName: string;
            givenName: string;
        };
        permissions: { permission: string }[];
    };
    userId: string;
}

interface CourseWork {
    alternateLink: string;
    assigneeMode: string;
    associatedWithDeveloper: boolean;
    courseId: string;
    creationTime: string;
    creatorUserId: string;
    description: string;
    id: string;
    materials: [];
    state: string;
    submissionModificationMode: string;
    title: string;
    updateTime: string;
    workType: string;
}

interface Classroom {
    alternateLink: string;
    calendarId: string;
    courseGroupEmail: string;
    courseState: string;
    courseWork: CourseWork[];
    creationTime: string;
    enrollmentCode: string;
    guardiansEnabled: boolean;
    id: string;
    name: string;
    ownerId: string;
    referencedInstructors: ReferencedInstructor[];
    referencedStudents: boolean; //TODO
    teacherFolder: { id: string };
    teacherGroupEmail: string;
    updateTime: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345
    },
    media: {
        height: 140
    },
    container: {
        margin: theme.spacing(1)
    }
}));
export default compose(
    withFirebase,
    withUser,
    withRouter
)((props: any) => {
    const { firebase, user, history } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Courses + CourseWork flattened
    const [courses, setCoursesData] = React.useState<Classroom[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    const fetchClassrooms = () => {
        firebase
            .fetchClassrooms()
            .then((data: { data: Classroom[] }) => {
                setCoursesData(data.data || []);
                setLoading(false);
            })
            .catch((e: any) => console.error(e));
    };

    React.useEffect(() => {
        (async () => {
            fetchClassrooms();

            return () => {
                //Cleanup
            };
        })();
    }, []);
    const classes = useStyles();
    return (
        <IonPage>
            {loading && <LinearProgress />}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Classrooms</IonTitle>
                    {/*<IonButtons slot="primary">*/}
                    {/*    <IonButton onClick={() => {}}>*/}
                    {/*        <AddIcon />*/}
                    {/*    </IonButton>*/}
                    {/*</IonButtons>*/}
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <Grid container className={classes.container} spacing={2}>
                    {courses.map((c: Classroom, index) => (
                        <Grid item xs={3} key={index}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={() => window.open(c.alternateLink, "_blank")}>
                                    <CardMedia
                                        className={classes.media}
                                        image="https://images.unsplash.com/photo-1507842217343-583bb7270b66?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2653&q=80"
                                        title={c.name}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {c.name}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </IonContent>
        </IonPage>
    );
});
