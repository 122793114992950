import ReferenceLibIcon from "@material-ui/icons/CollectionsOutlined";
import UserListIcon from "@material-ui/icons/PeopleOutline";
import TransactionsIcon from "@material-ui/icons/ReceiptOutlined";
import ClassroomsIcon from "@material-ui/icons/CastForEducationOutlined";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import GradeIcon from "@material-ui/icons/Grade";
import SchoolIcon from "@material-ui/icons/School";
import ProfilePage from "../../app/Profile";
import { Route } from "react-router-dom";
import React from "react";
import PortfolioReview from "../../app/PortfolioReview";
import ReferenceLibrary from "../../app/ReferenceLibrary";
import Programs from "../../app/Programs";
import CourseWork from "../../app/CourseWork";
import Transactions from "../../app/Transactions";
import Users from "../../app/Users";
import Onboarding from "../../app/Onboarding/Onboarding";
import GivePermissions from "../../app/Onboarding/GivePermissions";
import LoginPage from "../../app/Login/LoginPage";
import Welcome from "../../app/Welcome";
import Classrooms from "../../app/Courses";
import Course from "../../app/Courses/Course";

export const PublicRoutes = () => {
    return <Route component={LoginPage} />;
};

export const PrivateRoutes = () => {
    return (
        <>
            <Route exact path={`/`} component={Welcome} />
            <Route exact path={`/home/portfolio`} component={PortfolioReview} />
            <Route exact path={`/home/library`} component={ReferenceLibrary} />
            <Route exact path={`/home/programs`} component={Programs} />
            <Route exact path={`/home/coursework`} component={CourseWork} />
            <Route exact path={`/home/finances`} component={Transactions} />
            <Route exact path={`/home/users`} component={Users} />
            <Route exact path={`/home/profile`} component={ProfilePage} />
            <Route exact path={`/onboarding`} component={Onboarding} />
            <Route exact path={`/permissions`} component={GivePermissions} />

            <Route exact path={`/home/classrooms`} component={Classrooms} />
            <Route path={`/home/classrooms/:id`} component={Course} />
        </>
    );
};

const adminRoutes = [
    {
        path: "/portfolio",
        role: "admin",
        name: "Portfolio Queue",
        icon: GradeIcon,
        layout: "/home"
    },
    {
        path: "/library",
        role: "admin",
        name: "Reference Library",
        icon: ReferenceLibIcon,
        layout: "/home"
    },
    {
        path: "/users",
        role: "admin",
        name: "Users",
        icon: UserListIcon,
        layout: "/home"
    },
    {
        path: "/finances",
        role: "admin",
        name: "Transactions",
        icon: TransactionsIcon,
        layout: "/home"
    },
    {
        path: "/classrooms",
        role: "admin",
        name: "Classrooms",
        icon: ClassroomsIcon,
        layout: "/home"
    },
    {
        path: "/programs",
        role: "admin",
        name: "Programs",
        icon: SchoolIcon,
        layout: "/home"
    }
    // {
    //     path: "/coursework",
    //     role: "admin",
    //     name: "Course Work",
    //     icon: CourseWorkIcon,
    //     layout: "/home"
    // },
];

const teacherRoutes = [
    {
        path: "/portfolio",
        role: "teacher",
        name: "Portfolio review",
        icon: GradeIcon,

        layout: "/home"
    },
    {
        path: "/library",
        role: "teacher",
        name: "Reference Library",
        icon: ReferenceLibIcon,

        layout: "/home"
    },
    {
        path: "/users",
        role: "teacher",
        name: "My Students",
        icon: UserListIcon,

        layout: "/home"
    },
    // {
    //     path: "/coursework",
    //     role: "teacher",
    //     name: "Course Work",
    //     icon: CourseWorkIcon,
    //
    //     layout: "/home"
    // },

    {
        path: "/finances",
        role: "teacher",
        name: "Payroll",
        icon: TransactionsIcon,

        layout: "/home"
    },
    {
        path: "/classrooms",
        role: "teacher",
        name: "Classrooms",
        icon: ClassroomsIcon,
        layout: "/home"
    }
];

const studentRoutes = [
    {
        path: "/portfolio",
        role: "student",
        name: "Portfolio",
        icon: GradeIcon,

        layout: "/home"
    },
    // {
    //     path: "/classes",
    //     role: "student",
    //     name: "Classes",
    //     icon: DashboardIcon,
    //     component: Classes,
    //     layout: "/home"
    // },
    // {
    //     path: "/schedule",
    //     role: "student",
    //     name: "Schedule",
    //     icon: LibraryBooks,
    //     component: ClassSchedule,
    //     layout: "/home"
    // },
    {
        path: "/finances",
        role: "student",
        name: "Tuition Fees",
        icon: LibraryBooks,
        layout: "/home"
    }
];

export const menuRoutes = [...adminRoutes, ...teacherRoutes, ...studentRoutes];
