import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { DateTimePicker } from "@material-ui/pickers";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useState } from "react";
import { withFirebase, withUser } from "../../core/Firebase";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { initialStatePortfolioSubmission, initialStateProgram, PortfolioSubmission, Program } from "../../assets/types";
import { useSnackbar } from "notistack";

const EnrollDialog = (props: any) => {
    const { firebase, showing, show } = props;

    const [programs, setPrograms] = React.useState<Program[]>([]);
    React.useEffect(() => {
        (async () => {
            const data = await firebase.getPrograms();
            setPrograms(data);
        })();
    }, []);

    // Teacher assignment
    const [admins, setAdmins] = useState([]);
    React.useEffect(() => {
        (async () => {
            const admins = await firebase.getAdmins();
            setAdmins(admins);
        })();
    }, []);

    const [program, setProgram] = React.useState<Program>(initialStateProgram);

    const [portfolio, setPortfolio] = useState<PortfolioSubmission>(initialStatePortfolioSubmission);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
        <Dialog
            open={showing}
            onClose={() => {
                setProgram(initialStateProgram);
                show(false);
            }}
        >
            <DialogTitle>Enrolling student</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Program</InputLabel>
                    <Select
                        disabled={loading}
                        fullWidth
                        label="Program"
                        required
                        onChange={({ target: { value } }) => {
                            console.log(value);
                            //@ts-ignore
                            setProgram(value);
                        }}
                    >
                        {programs.map((l: any, index: number) => {
                            return (
                                <MenuItem key={index} value={l}>
                                    {l.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>Choose program to enroll student into</FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <DateTimePicker
                        fullWidth
                        disabled={loading}
                        label="Submission Due"
                        value={portfolio.submissionDueDate}
                        onChange={(submissionDueDate: any) => {
                            //@ts-ignore
                            setPortfolio({
                                ...portfolio,
                                submissionDueDate: new Date(submissionDueDate).toJSON()
                            });
                        }}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <DateTimePicker
                        fullWidth
                        disabled={loading}
                        label="Evaluation Due"
                        value={portfolio.evaluationDueDate}
                        onChange={(evaluationDueDate: any) => {
                            //@ts-ignore
                            setPortfolio({
                                ...portfolio,
                                evaluationDueDate: new Date(evaluationDueDate).toJSON()
                            });
                        }}
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <Autocomplete
                        disabled={loading}
                        options={admins}
                        onChange={(e, newValue) => {
                            setPortfolio({
                                ...portfolio,
                                revisedBy: newValue.uid,
                                revisedByName: `${newValue.fName} ${newValue.lName}`,
                                revisedByAvatar: newValue.profileAvatar
                            });
                        }}
                        getOptionLabel={(u: any) => {
                            if (!u) return "";
                            return `${u.fName} ${u.lName} (${u.role}) ${u.email}`;
                        }}
                        renderInput={params => (
                            <TextField {...params} label="To be revised after evaluation by..." variant="outlined" />
                        )}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading || !program.uid}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        setLoading(true);
                        return firebase
                            .enrollIntoProgram(props.student, program, portfolio)
                            .then((data: any) => {
                                enqueueSnackbar(data.message, {
                                    variant: "success"
                                });
                                setLoading(false);
                                show(false);
                            })
                            .catch((e: any) => {
                                enqueueSnackbar(e.message, {
                                    variant: "error"
                                });
                                setLoading(false);
                            });
                    }}
                >
                    Enroll
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withUser(withFirebase(EnrollDialog));
