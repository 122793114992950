export enum SubmissionStatus {
    UNSUBMITTED,
    BEING_ASSIGNED, // TEACHER_EVALUATING,
    TEACHER_EVALUATING, // ADMIN_REVISING,
    ADMIN_REVISING,
    COMPLETE
}

export interface Subject {
    name: string;
    description: string;
    attachmentsExpected: number;
    previewImg: string;
}

export interface Program {
    uid: string;
    name: string;
    description: string;
    subjects: Subject[];
    previewImg: string;
}

export const initialStateProgram: Program = {
    uid: "",
    name: "",
    description: "",
    subjects: [],
    previewImg: ""
};

export interface Piece {
    title: string;
    originalImg: string; //url
    markedImg: object;
    providedDescription: string; // by student
    givenFeedback: string; // by teacher
    updatedAt: Date;
}

export const initialStatePiece: Piece = {
    title: "",
    originalImg: "",
    markedImg: {},
    providedDescription: "",
    givenFeedback: "",
    updatedAt: new Date()
};

export interface PortfolioSubmission {
    uid: string;
    subject: string;
    program: string;
    // Due dates

    attachmentsExpected: number;
    attachments: Piece[];
    state: SubmissionStatus;
    previewImg: string;

    // student
    submissionDueDate: string;
    submittedAt: string;
    assignedTo: string;
    assignedToName: string;
    assignedToAvatar: string;

    // teacher
    evaluationDueDate: string;
    evaluatedAt: string;
    evaluatedBy: string;
    evaluatedByName: string;
    evaluatedByAvatar: string;

    // admin
    revisionDueDate: string;
    revisedAt: string;
    revisedBy: string;
    revisedByName: string;
    revisedByAvatar: string;

    score: number;
    teacherFeedback: string;
}

export const initialStatePortfolioSubmission: PortfolioSubmission = {
    uid: "",
    subject: "",
    program: "",

    attachments: [],
    attachmentsExpected: 0,
    previewImg:
        "https://images.unsplash.com/photo-1525278070609-779c7adb7b71?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1930&q=80",

    state: SubmissionStatus.UNSUBMITTED,

    submissionDueDate: new Date().toDateString(),
    submittedAt: "",
    assignedTo: "",
    assignedToName: "",
    assignedToAvatar: "",

    evaluationDueDate: new Date().toDateString(),
    evaluatedAt: "",
    evaluatedBy: "",
    evaluatedByName: "",
    evaluatedByAvatar: "",

    revisionDueDate: new Date().toDateString(),
    revisedAt: "",
    revisedBy: "",
    revisedByName: "",
    revisedByAvatar: "",

    score: 0,
    teacherFeedback: ""
};
