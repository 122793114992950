import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { withFirebase, withUser } from "../../core/Firebase";
import ImageUploader from "react-images-upload";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import CardMedia from "@material-ui/core/CardMedia";
import { languages } from "../../assets/variables";

function Profile(props) {
    const { firebase } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [form, setFormField] = useState(props.user || {});
    const onChange = field => value => {
        setFormField({
            ...form,
            [field]: value
        });
    };
    React.useEffect(() => {
        if (props.user) {
            setFormField(props.user);
        }
    }, [props.user]);

    let {
        fName,
        lName,
        wechatId,
        region,
        tel,
        aboutMe,
        email,
        address,
        city,
        postcode,
        program,
        studentType,
        goals,
        role,
        availability,
        canadianLegalStatus,
        supportedLanguages,
        educationLevel,
        educationDescription,
        profileAvatar,
        pe,
        uid
    } = form;
    if (!Array.isArray(role)) {
        role = [role];
    }
    if (!Array.isArray(studentType)) {
        studentType = [studentType];
    }
    if (!Array.isArray(region)) {
        region = [region];
    }
    if (!Array.isArray(program)) {
        program = [program];
    }

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        return firebase
            .updateUserProfile(form)
            .then(success => {
                enqueueSnackbar(success.data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                setLoading(false);
            })
            .catch(err => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });

        // if (form.password) {
        //     props.firebase
        //         .doPasswordUpdate(form.password)
        //         .then((user) => {
        //             onChange('password')('')
        //             enqueueSnackbar('Password Updated!', {
        //                 anchorOrigin: {
        //                     vertical: 'bottom',
        //                     horizontal: 'center',
        //                 },
        //                 variant: 'success'
        //             })
        //         })
        //         .catch(error => console.error(error))
        // }
    };

    const handlePasswordReset = () => {
        firebase.doPasswordReset(email);
    };

    const onAvatarDrop = async ([pic]) => {
        const photoReference = await firebase.updateUserProfileAvatar(uid, pic);
        enqueueSnackbar("Avatar Updated!", {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
            },
            variant: "success"
        });
        onChange("profileAvatar")(photoReference);
    };

    const handleChangeAvatar = () => {
        if (form.profileAvatar === false) onChange("profileAvatar")(props.user.profileAvatar);
        else onChange("profileAvatar")(false);
    };

    const courseNames = [
        "Online Course",
        "Art school Portfolio",
        "Portfolio Creation",
        "Children's Classes",
        "Fundamentals Course",
        "Digital Media",
        "Life Drawing Workshop"
    ];

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Profile</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <Card style={{ marginTop: 0 }}>
                    <form onSubmit={onSubmit}>
                        <CardHeader color="primary">
                            <h3>My Profile</h3>
                            <p>Settings and Preferences</p>
                        </CardHeader>
                        <CardContent>
                            <Grid container direction="row-reverse" justify="space-between">
                                <Grid item xs={12} sm={4} md={4}>
                                    <Card
                                        style={{
                                            maxWidth: "300px"
                                        }}
                                    >
                                        <CardMedia>
                                            {profileAvatar ? (
                                                <img
                                                    referrerPolicy="no-referrer"
                                                    style={{ width: "100%", align: "center" }}
                                                    src={profileAvatar}
                                                    alt="Avatar"
                                                />
                                            ) : (
                                                <ImageUploader
                                                    withIcon={true}
                                                    buttonText="Choose avatar image"
                                                    onChange={onAvatarDrop}
                                                    imgExtension={[".jpg", ".jpeg", ".png"]}
                                                    maxFileSize={5242880}
                                                />
                                            )}
                                        </CardMedia>

                                        <CardContent>
                                            <h6>{(role === "student" && `${studentType} ${role}`) || role}</h6>
                                            <h4>{fName + " " + lName}</h4>
                                            <p>{aboutMe}</p>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleChangeAvatar}
                                                fullWidth
                                            >
                                                Change Avatar
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8}>
                                    <TextField
                                        margin="normal"
                                        label={"First name"}
                                        value={fName}
                                        onChange={({ target: { value } }) => onChange("fName")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        label={"Last name"}
                                        value={lName}
                                        onChange={({ target: { value } }) => onChange("lName")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        rowsMax={4}
                                        label={"About"}
                                        value={aboutMe}
                                        onChange={({ target: { value } }) => onChange("aboutMe")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        label={"Email"}
                                        value={email}
                                        fullWidth
                                        onChange={({ target: { value } }) => onChange("email")(value)}
                                    />
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label={"WeChat ID"}
                                        value={wechatId}
                                        onChange={({ target: { value } }) => onChange("wechatId")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        label={"Phone number"}
                                        value={tel}
                                        onChange={({ target: { value } }) => onChange("tel")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        label={"Address"}
                                        rowsMax={4}
                                        value={address}
                                        onChange={({ target: { value } }) => onChange("address")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        label={"City"}
                                        value={city}
                                        onChange={({ target: { value } }) => onChange("city")(value)}
                                    />
                                    <TextField
                                        margin="normal"
                                        label={"Postcode"}
                                        value={postcode}
                                        onChange={({ target: { value } }) => onChange("postcode")(value)}
                                    />

                                    <FormControl margin="normal">
                                        <InputLabel>Region</InputLabel>
                                        <Select
                                            disabled={loading}
                                            label="Region"
                                            required
                                            multiple
                                            value={region}
                                            onChange={({ target: { value } }) => onChange("region")(value)}
                                        >
                                            <MenuItem value="Markham">Markham</MenuItem>
                                            <MenuItem value="Oakville">Oakville</MenuItem>
                                        </Select>
                                        <FormHelperText>This sets up location \ campus</FormHelperText>
                                    </FormControl>
                                    <br />

                                    {role.indexOf("student") > -1 && (
                                        <>
                                            <FormControl margin="normal" fullWidth>
                                                <Select
                                                    fullWidth
                                                    value={program}
                                                    onChange={({ target: { value } }) => onChange("program")(value)}
                                                    options={courseNames}
                                                >
                                                    {courseNames.map((name, index) => (
                                                        <MenuItem key={index} value={name}>
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>What program are you interested in ?</FormHelperText>
                                            </FormControl>
                                            <br />
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel>Goal</InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={loading}
                                                    help="Student Type"
                                                    required
                                                    multiple
                                                    value={goals || []}
                                                    onChange={({ target: { value } }) => onChange("goals")(value)}
                                                >
                                                    <MenuItem value="art-foundation">Art Foundations </MenuItem>
                                                    <MenuItem value="skill-learning">Skill learning</MenuItem>
                                                    <MenuItem value="art-portfolio">Arts Portfolio</MenuItem>
                                                    <MenuItem value="interest">For Fun</MenuItem>
                                                </Select>
                                                <FormHelperText>What's your goal?</FormHelperText>
                                            </FormControl>
                                            <FormControl margin="normal">
                                                <InputLabel>Student Type</InputLabel>
                                                <Select
                                                    disabled={loading}
                                                    required
                                                    multiple
                                                    value={studentType}
                                                    onChange={({ target: { value } }) => onChange("studentType")(value)}
                                                >
                                                    <MenuItem value="onCampus">On campus</MenuItem>
                                                    <MenuItem value="online">Online</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    Student can be online and\or doing portfolio in class on campus
                                                </FormHelperText>
                                            </FormControl>
                                        </>
                                    )}

                                    {role.indexOf("teacher") > -1 && (
                                        <>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                placeholder="I graduated from Seneca College with CPA"
                                                label={"What level of education you have ?"}
                                                value={educationLevel}
                                                onChange={({ target: { value } }) => onChange("educationLevel")(value)}
                                            />

                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                placeholder="I can create design wireframes and prototypes!"
                                                rows={3}
                                                rowsMax={4}
                                                label={"How would you describe your skills?"}
                                                value={educationDescription}
                                                onChange={({ target: { value } }) =>
                                                    onChange("educationDescription")(value)
                                                }
                                            />

                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Canadian Legal Status</InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={loading}
                                                    required
                                                    value={canadianLegalStatus}
                                                    onChange={({ target: { value } }) =>
                                                        onChange("canadianLegalStatus")(value)
                                                    }
                                                >
                                                    {[
                                                        "Resident",
                                                        "Work Permit",
                                                        "Study Permit",
                                                        "Ineligible for work"
                                                    ].map((l, index) => (
                                                        <MenuItem key={index} value={l}>
                                                            {l}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>Can you work with us ?</FormHelperText>
                                            </FormControl>

                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                placeholder="I can do part-time 2pm to 5pm 6 days a week!"
                                                rows={3}
                                                rowsMax={4}
                                                label={"What is your availability ?"}
                                                value={availability}
                                                onChange={({ target: { value } }) => onChange("availability")(value)}
                                            />

                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>Languages</InputLabel>
                                                <Select
                                                    fullWidth
                                                    disabled={loading}
                                                    label="Region"
                                                    required
                                                    multiple
                                                    value={supportedLanguages || []}
                                                    onChange={({ target: { value } }) =>
                                                        onChange("supportedLanguages")(value)
                                                    }
                                                >
                                                    {languages.map((l, index) => (
                                                        <MenuItem key={index} value={l.name}>
                                                            {l.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>Which languages do you speak ?</FormHelperText>
                                            </FormControl>
                                        </>
                                    )}
                                </Grid>
                                <Button color="primary" variant="contained" fullWidth type={"submit"}>
                                    Update Profile
                                </Button>
                            </Grid>
                        </CardContent>
                    </form>
                </Card>
            </IonContent>
        </IonPage>
    );
}

export default withUser(withFirebase(Profile));
