import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "es7-object-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "@ionic/react/css/core.css";
import { SnackbarProvider } from "notistack";
import { FirebaseContext, store } from "./core/Firebase";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "./theme/variables.css";
import "./theme/Menu.css";
import MainContainer from "./app/MainContainer";

const localization = {
    languages: [
        { name: "English", code: "en" },
        { name: "Chinese", code: "ch" }
    ],
    options: {
        renderToStaticMarkup: false,
        renderInnerHtml: true
    }
};

ReactDOM.render(
    <IonApp>
        {/*<LocalizeProvider initialize={localization}>*/}
        <FirebaseContext.Provider value={store}>
            <SnackbarProvider>
                <IonReactRouter>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MainContainer />
                    </MuiPickersUtilsProvider>
                </IonReactRouter>
            </SnackbarProvider>
        </FirebaseContext.Provider>
        {/*</LocalizeProvider>*/}
    </IonApp>,
    document.getElementById("root")
);
