import React from "react"
import {withRouter} from "react-router-dom"
import {withUser} from "../../core/Firebase"

import Admin from "./admin/Programs"
// import Teacher from "./teacher/Queue";
// import Student from "./student/Queue";

function Router(props) {
    const {role} = props.user

    if (role === "admin") return <Admin/>
        // else if (role === "teacher") {
        //     return <Teacher />;
        // } else if (role === "student") {
        //     return <Student />;
    // }
    else return <p>You don't have permission to see this page!</p>
}

export default withRouter(withUser(Router))
