import React from "react";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonToolbar } from "@ionic/react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { TouchApp } from "@material-ui/icons";
import { withFirebase } from "../../core/Firebase";
import { useSnackbar } from "notistack";

export default withFirebase(props => {
    console.log(
        ["admin", "teacher"].includes(props.firebase.activeUser.role),
        !props.firebase.activeUser.refresh_token
    );
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return (
        <IonPage>
            <IonContent>
                <Typography variant="h1" align="center">
                    Aureole Studios
                </Typography>
                {/*<Typography variant="h5" align="center">*/}
                {/*    Made to facilitate administration and learning.*/}
                {/*</Typography>*/}
                {["admin", "teacher"].includes(props.firebase.activeUser.role) &&
                !props.firebase.activeUser.refresh_token ? (
                    <IconButton
                        style={{ height: "60%", align: "center", width: "100%" }}
                        onClick={() => {
                            props.firebase
                                .giveAdministrativePermissions()
                                .then(() => {
                                    window.location.reload(false);
                                })
                                .catch(e => {
                                    enqueueSnackbar(`Whoops! Something went wrong. Contact administrator!`, {
                                        variant: "error"
                                    });
                                    console.error(e);
                                });
                        }}
                    >
                        <TouchApp />
                        Click to set up your permissions to control the classrooms, courses and more!
                    </IconButton>
                ) : (
                    <Box style={{ height: "40vh" }}>
                        <IonMenuButton>
                            <TouchApp />
                            Start here
                        </IonMenuButton>
                    </Box>
                )}
            </IonContent>
        </IonPage>
    );
});
