import { SubmissionStatus } from "../../../assets/types";
import { IonAvatar, IonBadge, IonLabel } from "@ionic/react";
import React from "react";

export default ({
    role,
    portfolio: {
        program,
        subject,
        submissionDueDate,
        evaluationDueDate,
        attachments,
        attachmentsExpected,
        state,
        teacherFeedback,
        score,

        evaluatedByName,
        revisedByName,

        assignedToName,

        previewImg
    }
}) => (
    <>
        {attachments &&
            attachments.map((a, index) => (
                <IonAvatar slot="end" key={index}>
                    <img src={a.originalImg} />
                </IonAvatar>
            ))}

        {state === SubmissionStatus.BEING_ASSIGNED && role === "admin" && (
            <IonBadge slot="end">Requires attention!</IonBadge>
        )}

        {state === SubmissionStatus.TEACHER_EVALUATING && role === "teacher" && (
            <IonBadge slot="end">Requires evaluation!</IonBadge>
        )}

        {state === SubmissionStatus.ADMIN_REVISING && role === "admin" && (
            <IonBadge slot="end">Requires revision!</IonBadge>
        )}

        <IonLabel>
            <h2>
                {program}: {subject}
            </h2>
            {state === SubmissionStatus.UNSUBMITTED && (
                <>
                    {role === "student" ? (
                        <p>
                            You have submitted {(attachments && attachments.length) || 0}/{attachmentsExpected} pieces.{" "}
                            <strong>Due {new Date(submissionDueDate).toDateString()}</strong>
                        </p>
                    ) : (
                        <p>
                            <strong>{assignedToName}</strong>'s portfolio submission due{" "}
                            {new Date(submissionDueDate).toDateString()}
                        </p>
                    )}
                </>
            )}
            {state === SubmissionStatus.BEING_ASSIGNED && (
                <>
                    {role === "student" ? (
                        <>
                            <h3>Selecting the right teacher</h3>
                            <p>{revisedByName} is selecting best teacher to evaluate this portfolio</p>
                        </>
                    ) : (
                        <>
                            <h3>Select the right teacher!</h3>
                            <p>
                                <strong>{assignedToName}</strong>'s portfolio
                            </p>
                        </>
                    )}
                </>
            )}
            {state === SubmissionStatus.TEACHER_EVALUATING && (
                <>
                    {role === "student" && (
                        <p>
                            {evaluatedByName} is <strong>evaluating</strong> your portfolio
                        </p>
                    )}
                    {role === "admin" && (
                        <p>
                            {evaluatedByName} is <strong>evaluating</strong> {assignedToName}'s portfolio
                        </p>
                    )}
                    {role === "teacher" && <p>Evaluation in progress</p>}

                    <p>Due {new Date(evaluationDueDate).toDateString()}</p>
                </>
            )}
            {state === SubmissionStatus.ADMIN_REVISING && (
                <>
                    <h3>
                        {revisedByName} is making sure {evaluatedByName} has marked it right
                    </h3>
                    {role !== "student" && <p>{assignedToName}'s portfolio</p>}
                </>
            )}
            {state === SubmissionStatus.COMPLETE && (
                <>
                    <h3>Complete</h3>
                    {role !== "student" && <p>{assignedToName}'s portfolio</p>}
                    <p>
                        {evaluatedByName}: {teacherFeedback} {score}/100
                    </p>
                </>
            )}
        </IonLabel>
    </>
);
