import {
    IonContent,
    IonFooter,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenu,
    IonNote,
    IonPage,
    IonSplitPane
} from "@ionic/react";
import React from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import { AuthenticationContext, store } from "../../core/Firebase";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { menuRoutes, PrivateRoutes, PublicRoutes } from "../../core/sidebar-routes";
import { createMuiTheme, createStyles, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { withRouter } from "react-router";
import Onboarding from "../Onboarding/Onboarding";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default withRouter(
    observer(props => {
        const {
            history,
            history: { location }
        } = props;

        const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
        const theme = React.useMemo(
            () =>
                createMuiTheme({
                    palette: {
                        type: prefersDarkMode ? "dark" : "light"
                    }
                }),
            [prefersDarkMode]
        );

        const [menu, setMenuRoutes] = React.useState([]);

        const user = store.activeUser;

        React.useEffect(() => {
            if (store.activeUser) {
                setMenuRoutes(
                    _.compact(menuRoutes.map(route => (route.role === user.role ? route : false))).map(
                        (appPage, index) => (
                            <IonItem
                                key={index}
                                className={location.pathname.indexOf(appPage.path) > -1 ? "selected" : ""}
                                routerLink={appPage.layout + appPage.path}
                                routerDirection="none"
                                lines="none"
                                detail={false}
                            >
                                <appPage.icon></appPage.icon>
                                <IonLabel>{appPage.name}</IonLabel>
                            </IonItem>
                        )
                    )
                );
            }
        }, [props.history.location, user]);

        if (!store.authCheckComplete) {
            return <IonLoading isOpen={true} message="AureoleStudios is warming up..." />;
        }

        if (!store.activeUser) {
            return (
                <ThemeProvider theme={theme}>
                    <AuthenticationContext.Provider value={user}>
                        <PublicRoutes />
                    </AuthenticationContext.Provider>
                </ThemeProvider>
            );
        }

        if (store.activeUser && !store.activeUser.wentThroughOnboarding) {
            return (
                <ThemeProvider theme={theme}>
                    <Onboarding />
                </ThemeProvider>
            );
        }

        return (
            <ThemeProvider theme={theme}>
                <AuthenticationContext.Provider value={user}>
                    <IonSplitPane contentId="main-content">
                        <IonMenu
                            disabled={
                                history.location.pathname === "/onboarding" ||
                                (["admin", "teacher"].includes(store.activeUser.role) &&
                                    !store.activeUser.refresh_token)
                            }
                            contentId="main-content"
                            type="overlay"
                        >
                            <IonContent>
                                <IonList>
                                    <IonItem routerLink="/home/profile">
                                        <Avatar alt={user.fName} src={user.profileAvatar} />
                                        <IonListHeader>{user.fName + " " + user.lName}</IonListHeader>
                                    </IonItem>

                                    <IonNote>Logged in as {user.role}</IonNote>

                                    {menu}
                                </IonList>

                                <IonFooter className="bar-stable" ng-click="logout()">
                                    <IonItem
                                        className={location.pathname.indexOf("/profile") > -1 ? "selected" : ""}
                                        routerLink={"/home/profile"}
                                        routerDirection="none"
                                        lines="none"
                                        detail={false}
                                    >
                                        <Person />
                                        <IonLabel>Profile</IonLabel>
                                    </IonItem>
                                    <IonItem
                                        routerLink={"/login"}
                                        routerDirection="none"
                                        lines="none"
                                        onClick={() => store.doSignOut()}
                                        detail={false}
                                    >
                                        <ExitToAppIcon />
                                        <IonLabel>Logout</IonLabel>
                                    </IonItem>
                                </IonFooter>
                            </IonContent>
                        </IonMenu>

                        <IonPage id="main-content">
                            <PrivateRoutes />
                        </IonPage>
                    </IonSplitPane>
                </AuthenticationContext.Provider>
            </ThemeProvider>
        );
    })
);
