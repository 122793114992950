import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UserTable from "./UserTable";
import { withUser } from "../../core/Firebase";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";

const Index = props => {
    const [tab, setValue] = React.useState(0);
    const handleChange = (event, newValue) => setValue(newValue);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>User Table</IonTitle>
                    <IonButtons slot="end">
                        <Tabs value={tab} onChange={handleChange}>
                            <Tab label="Students" />
                            {["admin"].indexOf(props.user.role) > -1 && <Tab label="Teachers" />}
                            {["teacher", "admin"].indexOf(props.user.role) > -1 && <Tab label="Admins" />}
                        </Tabs>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <UserTable role={tab} />
            </IonContent>
        </IonPage>
    );
};

export default withUser(Index);
