import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase, withUser } from "../../../core/Firebase";
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemSliding,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { PortfolioSubmission } from "../../../assets/types";
import PortfolioDialogue from "./ReviewPortfolio";
import { Button, createStyles, Fab, LinearProgress, Theme } from "@material-ui/core";
import Label from "../components/QueueListItem";
import { Add } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: "absolute",
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    })
);

function Portfolio(props: any) {
    const { history } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [listing, setListing] = React.useState([]);
    const [usersMeta, setUsers] = React.useState([]);
    const fetchListing = async () => {
        const { data } = await props.firebase.subscribeToStudentsPortfolioSubmissions();
        setListing(data);
        setLoading(false);
    };
    React.useEffect(() => {
        fetchListing();
    }, []);

    const [selectedData, selectData] = React.useState();

    const [showingPortfolioDialogue, setShowPortfolioDialogue] = React.useState(false);

    return (
        <IonPage>
            {loading && <LinearProgress />}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Portfolio review</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList>
                    {!listing.length && (
                        <Button fullWidth>
                            <IonItem
                                onClick={() => {
                                    enqueueSnackbar(
                                        `Want to enroll student into a portfolio program ? Ask administrator to help you out!`
                                    );
                                }}
                            >
                                <IonLabel>
                                    <h2>Good job!</h2>
                                    <h3>You have no outstanding portfolios to review.</h3>
                                    <p>Tap to enroll a student to portfolio funnel!</p>
                                </IonLabel>
                            </IonItem>
                        </Button>
                    )}
                    {listing.map((i: PortfolioSubmission, index) => {
                        return (
                            <IonItemSliding key={index}>
                                <IonItem
                                    onClick={() => {
                                        selectData(i);
                                        setShowPortfolioDialogue(true);
                                    }}
                                >
                                    <Label portfolio={i} role={props.user.role} />
                                </IonItem>
                            </IonItemSliding>
                        );
                    })}
                </IonList>

                <Fab
                    onClick={() => {
                        enqueueSnackbar(
                            `Want portfolio funnel for your student ? Enroll the student from User Table!`,
                            {
                                persist: true,
                                action: (
                                    <>
                                        <video
                                            controls={true}
                                            height={320}
                                            src={require("../../../assets/enroll_a_student_into_portfolio.mp4")}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                history.push("/home/users");
                                            }}
                                        >
                                            Go To User Table
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {
                                                closeSnackbar();
                                            }}
                                        >
                                            Close
                                        </Button>
                                    </>
                                )
                            }
                        );
                    }}
                    className={classes.fab}
                    color="primary"
                >
                    <Add />
                </Fab>
            </IonContent>

            <PortfolioDialogue
                showing={showingPortfolioDialogue}
                setShow={setShowPortfolioDialogue}
                selectData={selectData}
                selectedData={selectedData}
                fetch={fetchListing}
            />
        </IonPage>
    );
}

export default compose(withRouter, withFirebase, withUser)(Portfolio);
