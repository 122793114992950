import React, { useState } from "react";
import _ from "lodash";
import { withFirebase, withUser } from "../../core/Firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";

const ApplyAssignmentDialogue = props => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user, firebase, assignment } = props;

    const [loading, setLoading] = useState(false);

    const [classrooms, setClassrooms] = useState([]);

    React.useEffect(() => {
        (async () => {
            const { data } = await firebase.fetchClassrooms();
            setClassrooms(data);
        })();
    }, []);
    React.useEffect(() => {
        if (props.selectedData) {
            setFormField({
                ...props.selectedData
            });
        }
    }, [props.selectedData]);

    // Edit unimplemented
    const [form, setFormField] = useState(props.selectedData || {});
    const onChange = field => value => {
        setFormField({
            ...form,
            [field]: value
        });
    };

    // console.log(props.selectedData)

    // Form submission
    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);

        return firebase
            .createCourseWorkFromPreset(form.classroom, form, assignment)
            .then(success => {
                enqueueSnackbar(success.data, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "success"
                });
                onClose();
            })
            .catch(err => {
                enqueueSnackbar(err.message, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    variant: "error"
                });
                setLoading(false);
            });
    };

    const onClose = () => {
        props.setShow(false);
        setLoading(false);
        setFormField({});
    };

    // console.log(form, )
    return (
        <>
            <Dialog fullWidth maxWidth="sm" onClose={onClose} open={!!props.showing}>
                <form onSubmit={onSubmit}>
                    <DialogTitle>
                        {" "}
                        Publish Assignment
                        <IconButton
                            style={{
                                position: "absolute",
                                right: 1,
                                top: 1,
                                color: "grey"
                            }}
                            aria-label="close"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        {loading && <LinearProgress />}
                        {/*Course Selection*/}
                        <Select
                            label="Apply To"
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={({ target: { value } }) => onChange("classroom")(value)}
                            value={form.classroom}
                            disabled={loading}
                        >
                            {_.map(classrooms, ({ name, id: reference_uid }, index) => (
                                <MenuItem key={index} value={reference_uid}>
                                    {`${name}`}
                                </MenuItem>
                            ))}
                        </Select>

                        {/* Custom Description */}
                        <TextField
                            id="desc"
                            label="Instructions"
                            fullWidth
                            disabled={loading}
                            multiline
                            rows={3}
                            rowsMax={12}
                            value={form.instructions}
                            onChange={({ target: { value } }) => onChange("instructions")(value)}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button color="secondary" onClick={onClose} disabled={loading}>
                            Cancel
                        </Button>

                        <Button color="primary" type="submit" variant="contained" disabled={loading}>
                            Publish Assignment
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default withFirebase(withUser(ApplyAssignmentDialogue));
