import React, { useEffect, useRef, useState } from "react";
import { withFirebase } from "../../core/Firebase";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { IonLoading, IonPage, IonSlide, IonSlides } from "@ionic/react";
import { withRouter } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, createStyles, Fab, IconButton } from "@material-ui/core";
import { Check, ExitToApp, NavigateBefore, NavigateNext, TouchApp } from "@material-ui/icons";
import { observer } from "mobx-react";
import { toJS } from "mobx";

export default withRouter(
    withFirebase(
        observer(props => {
            const { enqueueSnackbar, closeSnackbar } = useSnackbar();
            const { firebase, history } = props;
            const [loading, setLoading] = useState(false);
            const [isBeginning, setBeginning] = useState(true);
            const [isEnding, setEnding] = useState(false);

            const [form, setFormField] = useState({});
            const onChange = field => value =>
                setFormField({
                    ...form,
                    [field]: value
                });

            const onSubmit = () => {
                setLoading(true);

                return firebase
                    .completeOnboarding(form)
                    .catch(e => {
                        enqueueSnackbar(`Whoops! Something went wrong. Contact administrator!`, {
                            variant: "error"
                        });
                        console.error(e);
                        setLoading(false);
                    })
                    .finally(() => {
                        window.location.reload(false);
                    });
            };

            let {
                fName,
                lName,
                wechatId,
                region,
                tel,
                aboutMe,
                email,
                address,
                city,
                postcode,
                program,
                studentType,
                goals,
                role,
                availability,
                canadianLegalStatus,
                supportedLanguages,
                educationLevel,
                educationDescription
            } = form;
            const courseNames = [
                "Online Course",
                "Art school Portfolio",
                "Portfolio Creation",
                "Children's Classes",
                "Fundamentals Course",
                "Digital Media",
                "Life Drawing Workshop"
            ];
            // Make sure the multiple fields are arrays
            if (!Array.isArray(studentType)) {
                studentType = [studentType];
            }
            if (!Array.isArray(region)) {
                region = [region];
            }
            if (!Array.isArray(program)) {
                program = [program];
            }

            const slidesRef = useRef();

            useEffect(() => {
                // console.log(toJS(firebase.activeUser));
                setFormField(toJS(firebase.activeUser));

                slidesRef.current.lockSwipes(true);
            }, []);

            const updateButtons = () => {
                if (slidesRef.current) {
                    slidesRef.current.isEnd().then(res => {
                        console.log(res);
                        setEnding(res);
                    });
                    slidesRef.current.isBeginning().then(res => {
                        console.log(res);
                        setBeginning(res);
                    });
                }
            };

            const classes = makeStyles(theme =>
                createStyles({
                    slide: {
                        padding: theme.spacing(2),
                        // alignText: "justify",
                        justifyContent: "space-evenly",
                        flexDirection: "column",
                        height: "auto"
                    },
                    input: {
                        margin: theme.spacing(1)
                    },
                    previous: {
                        position: "absolute",
                        bottom: theme.spacing(2),
                        left: theme.spacing(2)
                    },
                    next: {
                        position: "absolute",
                        bottom: theme.spacing(2),
                        right: theme.spacing(2)
                    }
                })
            )();

            return (
                <IonPage>
                    <Grid container justify={"center"} style={{ height: "100%" }}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <IonSlides ref={slidesRef} pager={false}>
                                <IonSlide className={classes.slide}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt="" src="" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Hi, we are Aureole Studios, and we are excited to have you here! "
                                            secondary="We are looking forward sharing the world of art with you!"
                                        />
                                    </ListItem>

                                    <Box>
                                        <TextField
                                            className={classes.input}
                                            fullWidth
                                            variant="outlined"
                                            label={"First name"}
                                            value={fName}
                                            onChange={({ target: { value } }) => onChange("fName")(value)}
                                        />

                                        <TextField
                                            className={classes.input}
                                            fullWidth
                                            variant="outlined"
                                            label={"Last name"}
                                            value={lName}
                                            onChange={({ target: { value } }) => onChange("lName")(value)}
                                        />

                                        <TextField
                                            className={classes.input}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            placeholder="I am currently studying Illustration and want to apply to Sheridan college!"
                                            rows={3}
                                            rowsMax={4}
                                            label={"Couple words to describe yourself"}
                                            value={aboutMe}
                                            onChange={({ target: { value } }) => onChange("aboutMe")(value)}
                                        />
                                    </Box>
                                </IonSlide>

                                <IonSlide className={classes.slide}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt="" src="" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="We teach a variety of subjects."
                                            secondary="What would you like to study with us ?"
                                        />
                                    </ListItem>

                                    <FormControl margin="normal" fullWidth variant="outlined" className={classes.input}>
                                        <Select
                                            fullWidth
                                            value={program}
                                            onChange={({ target: { value } }) => onChange("program")(value)}
                                            options={courseNames}
                                        >
                                            {courseNames.map(name => (
                                                <MenuItem value={name}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Which program are you interested in ?</FormHelperText>
                                    </FormControl>

                                    <FormControl margin="normal" fullWidth variant="outlined" className={classes.input}>
                                        <Select
                                            fullWidth
                                            disabled={loading}
                                            help="Student Type"
                                            required
                                            multiple
                                            value={goals || []}
                                            onChange={({ target: { value } }) => onChange("goals")(value)}
                                        >
                                            <MenuItem value="art-foundation">Art Foundations </MenuItem>
                                            <MenuItem value="skill-learning">Skill learning</MenuItem>
                                            <MenuItem value="art-portfolio">Arts Portfolio</MenuItem>
                                            <MenuItem value="interest">For Fun</MenuItem>
                                        </Select>
                                        <FormHelperText>What's your goal?</FormHelperText>
                                    </FormControl>

                                    <span>
                                        <FormControl margin="normal" variant="outlined" className={classes.input}>
                                            <Select
                                                disabled={loading}
                                                required
                                                multiple
                                                value={studentType}
                                                onChange={({ target: { value } }) => onChange("studentType")(value)}
                                            >
                                                <MenuItem value="onCampus">On campus</MenuItem>
                                                <MenuItem value="online">Online</MenuItem>
                                            </Select>
                                            <FormHelperText>How would you like to study ?</FormHelperText>
                                        </FormControl>

                                        <FormControl margin="normal" variant="outlined" className={classes.input}>
                                            <Select
                                                disabled={loading}
                                                label="Region"
                                                required
                                                multiple
                                                value={region}
                                                onChange={({ target: { value } }) => onChange("region")(value)}
                                            >
                                                <MenuItem value="Markham">Markham</MenuItem>
                                                <MenuItem value="Oakville">Oakville</MenuItem>
                                            </Select>
                                            <FormHelperText>Closest Campus</FormHelperText>
                                        </FormControl>
                                    </span>
                                </IonSlide>

                                <IonSlide className={classes.slide}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt="" src="" />
                                        </ListItemAvatar>
                                        <ListItemText primary="You are good to go!" />
                                    </ListItem>

                                    {/*<Button fullWidth variant="outlined">*/}
                                    {/*    Give us a call to enroll into the program!*/}
                                    {/*    <a href={`tel:16479630829`}> (647) 963-0829</a>*/}
                                    {/*</Button>*/}

                                    <IconButton
                                        fullWidth
                                        disabled={loading}
                                        style={{ height: "50vh" }}
                                        onClick={onSubmit}
                                    >
                                        <TouchApp /> Start using the system
                                    </IconButton>
                                </IonSlide>
                            </IonSlides>

                            <Fab
                                className={classes.previous}
                                onClick={() => {
                                    if (isBeginning) {
                                        console.log("signing out");
                                        firebase.doSignOut();
                                    } else {
                                        slidesRef.current.lockSwipes(false);
                                        slidesRef.current.slidePrev();
                                        slidesRef.current.lockSwipes(true);
                                    }

                                    updateButtons();
                                }}
                            >
                                {isBeginning ? <ExitToApp /> : <NavigateBefore />}
                            </Fab>

                            <Fab
                                className={classes.next}
                                onClick={() => {
                                    if (isEnding) {
                                        onSubmit();
                                    }
                                    slidesRef.current.lockSwipes(false);
                                    slidesRef.current.slideNext();
                                    slidesRef.current.lockSwipes(true);
                                    updateButtons();
                                }}
                            >
                                {isEnding ? <TouchApp /> : <NavigateNext />}
                            </Fab>
                        </Grid>
                    </Grid>

                    <IonLoading isOpen={loading} message={"Making it right..."} />
                </IonPage>
            );
        })
    )
);
