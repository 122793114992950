import * as React from "react";
import { useState } from "react";
// @material-ui/core components
// core components
import { withFirebase, withUser } from "../../core/Firebase";
import { compose } from "recompose";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

function TransactionDialogue(props) {
    const {
        firebase,

        fetchData,
        showing,
        setShow,
        selectedData,
        selectData,

        users
    } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const emptySelection = {
        remark: "",
        paymentMethod: "",
        userReference: "",
        amount: "0",
        type: "",
        status: "",
        currency: "CAD",
        commission_percentage: "0",
        total: "0",
        expiry_date: moment()
            .add(7, "day")
            .toDate(),
        commission_type: "",
        createdAt: new Date(),
        region: ""
    };

    const [form, setFormField] = useState(props.selectedData || emptySelection);
    const onChange = field => async value => {
        setFormField({
            ...form,
            /// Make the necessary change
            [field]: value
        });
    };

    React.useEffect(() => {
        if (selectedData) {
            setFormField(selectedData);
        }
        if (props.defaultRegion) {
            onChange("region")(props.defaultRegion);
        }
    }, [selectedData, props.defaultRegion]);

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        // If type is tuition fee then jsonify the date
        if (form.type !== "Tuition Fee") form.expiry_date = null;

        form.total = form.amount - (form.amount * form.commission_percentage || 0) / 100;
        if (form.createdAt instanceof Date) form.createdAt = form.createdAt.toJSON();

        if (!uid) {
            firebase
                .createTransaction(form)
                .then(({ data }) => {
                    enqueueSnackbar(data, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "success"
                    });
                    onClose();
                })
                .catch(({ message }) => {
                    enqueueSnackbar(message, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "error"
                    });
                    setLoading(false);
                });
        } else {
            firebase
                .updateTransaction(uid)(form)
                .then(success => {
                    enqueueSnackbar(success.data, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "info"
                    });
                    onClose();
                })
                .catch(err => {
                    enqueueSnackbar(err.message, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        variant: "error"
                    });
                    setLoading(false);
                });
        }
    };

    const onClose = () => {
        fetchData();
        selectData(false);
        setShow(false);
        setFormField(emptySelection);
        setLoading(false);
    };

    const currencies = [
        {
            value: "CAD",
            label: "$"
        },
        {
            value: "USD",
            label: "$"
        },
        {
            value: "YUAN",
            label: "¥"
        }
    ];

    const {
        uid,
        remark,
        paymentMethod,
        userReference,
        amount,
        type,
        currency,
        commission_percentage,
        total,
        expiry_date,
        commission_type,
        status,
        createdAt,
        region
    } = form;

    return (
        <Dialog fullWidth onClose={onClose} open={showing}>
            <form onSubmit={onSubmit}>
                <DialogTitle>
                    Transactions
                    <IconButton
                        style={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            color: "grey"
                        }}
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {loading && <LinearProgress />}

                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Transaction type</InputLabel>
                        <Select
                            disabled={loading}
                            label="Transaction Type"
                            required
                            fullWidth
                            value={type}
                            onChange={({ target: { value } }) => onChange("type")(value)}
                        >
                            <MenuItem value="Payroll">Payroll</MenuItem>
                            <MenuItem value="Tuition Fee">Tuition Fee</MenuItem>
                            <MenuItem value="Business Expense">Business Expense</MenuItem>
                            <MenuItem value="Taken Class Charge">Taken Class Charge</MenuItem>
                        </Select>
                        <FormHelperText>Kind of transaction is this ?</FormHelperText>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel>Region</InputLabel>
                        <Select
                            disabled={loading}
                            required
                            fullWidth
                            value={region}
                            onChange={({ target: { value } }) => onChange("region")(value)}
                        >
                            <MenuItem value="Markham">Markham</MenuItem>
                            <MenuItem value="Oakville">Oakville</MenuItem>
                        </Select>
                        <FormHelperText>Campus location</FormHelperText>
                    </FormControl>

                    <FormControl margin="normal">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disabled={loading}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Creation date"
                                value={createdAt}
                                onChange={value => onChange("createdAt")(value)}
                                KeyboardButtonProps={{
                                    "aria-label": "Override for creation date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>

                    <TextField
                        margin="normal"
                        fullWidth
                        rows={3}
                        multiline
                        value={remark}
                        disabled={loading}
                        placeholder="Remark..."
                        onChange={({ target: { value } }) => onChange("remark")(value)}
                    />

                    <Autocomplete
                        fullWidth
                        disabled={loading}
                        id="userReference"
                        options={users}
                        onChange={(e, newValue) => {
                            newValue && onChange("userReference")(newValue.uid);
                        }}
                        getOptionLabel={u => `${u.fName} ${u.lName} (${u.role}) ${u.email}`}
                        value={users.filter(({ uid }) => userReference === uid)[0] || null}
                        renderInput={params => <TextField {...params} label="Referenced User..." />}
                    />

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Payment Method</InputLabel>
                        <Select
                            disabled={loading}
                            fullWidth
                            required
                            value={paymentMethod}
                            onChange={({ target: { value } }) => onChange("paymentMethod")(value)}
                        >
                            <MenuItem value="EMT">EMT</MenuItem>
                            <MenuItem value="Wire">Wire</MenuItem>
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="Credit Card">Credit Card</MenuItem>
                            <MenuItem value="WeChat">WeChat</MenuItem>
                            <MenuItem value="AliPay">AliPay</MenuItem>
                            {/*<MenuItem value='Balance Charge'>Balance Charge</MenuItem>*/}
                        </Select>
                    </FormControl>

                    <TextField
                        margin="normal"
                        id={"amount"}
                        label="Amount"
                        disabled={loading}
                        value={amount}
                        onChange={({ target: { value } }) => onChange("amount")(value)}
                    />
                    <FormControl style={{ width: "140px" }} margin="normal">
                        <InputLabel>Payment Currency</InputLabel>
                        <Select
                            disabled={loading}
                            required
                            value={currency || "CAD"}
                            onChange={e => onChange("currency")(e.target.value)}
                        >
                            {currencies.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <br />

                    <TextField
                        margin="normal"
                        disabled={loading}
                        id={"commission_percentage"}
                        label="Commission"
                        startAdornment={`%`}
                        value={commission_percentage}
                        onChange={({ target: { value } }) => onChange("commission_percentage")(value)}
                    />

                    <FormControl style={{ width: "140px" }} margin="normal">
                        <InputLabel>Commission Type</InputLabel>
                        <Select
                            disabled={loading}
                            value={commission_type}
                            onChange={e => onChange("commission_type")(e.target.value)}
                        >
                            <MenuItem value="Discount">Discount</MenuItem>
                            <MenuItem value="Commission">Commission</MenuItem>
                        </Select>
                    </FormControl>
                    <br />

                    <TextField
                        label="Total"
                        value={form.amount - (form.amount * form.commission_percentage) / 100 || form.amount}
                        disabled={true}
                        onChange={({ target: { value } }) => onChange("total")(value)}
                    />
                    <br />
                    <FormControl style={{ width: "140px" }} margin="normal" fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            disabled={loading}
                            fullWidth
                            value={status}
                            onChange={({ target: { value } }) => onChange("status")(value)}
                        >
                            <MenuItem value="Due Payment">Due Payment</MenuItem>
                            <MenuItem value="Pending Payment">Pending Payment</MenuItem>
                            <MenuItem value="Complete Payment">Complete Payment</MenuItem>
                            <MenuItem value="Annulled">Annulled</MenuItem>
                        </Select>
                    </FormControl>

                    {form.type === "Tuition Fee" && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required={true}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="expiry_date"
                                label={`Expiry Date: ${new Date(expiry_date) > new Date() ? "Valid" : "Expired"}`}
                                value={expiry_date}
                                onChange={onChange("expiry_date")}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button color="primary" fullWidth variant="contained" type={"submit"} disabled={loading}>
                        Publish
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default compose(withUser, withFirebase)(TransactionDialogue);
